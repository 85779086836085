import { Component, ElementRef, Injector, OnInit, Signal, WritableSignal, computed, effect, inject, signal, viewChildren } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CdkAccordionItem } from '@angular/cdk/accordion';
import { map } from 'rxjs';

import { AreYouSure2Component } from '../../shared/are-you-sure2/are-you-sure2.component';
import { IndustrialUnitEditComponent } from '../industrial-unit-edit/industrial-unit-edit.component';
import { TransactionBaseComponent } from '../../economics/transaction-base/transaction-base.component';

import { InformationPopulationService } from '../../services/information-population.service';
import { InformationStarSystemService } from '../../services/information-star-system.service';

import { InformationPopulation } from '../../interfaces/information-population';
import { InformationStarSystem } from '../../interfaces/information-star-system';
import { Transaction } from '../../interfaces/transaction';

@Component({
  selector: 'industrial-expansion',
  templateUrl: './industrial-expansion.component.html',
})
export class IndustrialExpansionComponent extends TransactionBaseComponent implements OnInit {
  injectorObject = { injector: inject(Injector) };
  accordions = viewChildren(CdkAccordionItem);
  summaryDivs = viewChildren('accordionSummary', { read: ElementRef });
  industrialComponentElement: HTMLElement;

  private informationPopulationService = inject(InformationPopulationService);
  private informationStarSystemService = inject(InformationStarSystemService);

  canEdit: boolean = false;
  showAll: boolean = false;
  myPopulationInfo: Signal<InformationPopulation[]>;
  starSystemsWithPopulations: WritableSignal<InformationStarSystem[]> = signal([]);
  starSystemsPopulationInfo: { [key: string]: InformationPopulation[]; } = {};
  iuAmount: number;
  systemsSet: WritableSignal<string[]> = signal([]);
  transactionsIndustrial: WritableSignal<Transaction[]> = signal([]);

  transactionIndustrialByBodyId: Signal<{ [key: string]: Transaction; }> = computed(() => {
    let byBodyId: { [key: string]: Transaction; } = {} as { [key: string]: Transaction; };
    let tByBodyId = this.transactions().reduce((acc, transaction) => {
      acc[transaction.bodyId] = transaction;
      return acc;
    }, byBodyId);
    return tByBodyId;
  });

  constructor (
    private industrialComponent: ElementRef,
  ) {
    super();
    this.transactionType = "industrial";
    this.industrialComponentElement = this.industrialComponent.nativeElement;
    this.canEdit = this.race().readyForEconomicsTurn < this.session.turnEdit();
    this.showAll = this.canEdit;
  };

  override ngOnInit (): void {
    super.ngOnInit();

    this.myPopulationInfo = toSignal<InformationPopulation[]>(
      this.informationPopulationService.getInformationPopulationForRaceId(this.race()._id).pipe(
        map(informationPopulations => {
          let systemsSet = new Set<string>();
          let filteredInformationPopulations = informationPopulations.filter(informationPopulation => {
            let hasPopulation = informationPopulation.ptu > 0;
            if (hasPopulation) {
              systemsSet.add(informationPopulation.starSystemId);
              let informationPopulation2: InformationPopulation = informationPopulation;
              informationPopulation2.maxIu = Math.floor(informationPopulation2.pu / 2);
              this.starSystemsPopulationInfo[informationPopulation.starSystemId] = (this.starSystemsPopulationInfo[informationPopulation.starSystemId] || []).concat(informationPopulation2);
            }
            return hasPopulation;
          });
          this.systemsSet.set(Array.from(systemsSet));
          return filteredInformationPopulations;
        })
      ), this.injectorObject
    );

    effect(() => {
      let systemsSet = this.systemsSet();
      if (!systemsSet || systemsSet.length === 0) {
        return;
      }
      this.informationStarSystemService.getInformationStarSystemForRaceId(this.race()._id).subscribe(
        informationStarSystems => {
          this.starSystemsWithPopulations.set(informationStarSystems.filter(
            informationStarSystem => systemsSet.includes(informationStarSystem.starSystemId)
          ));
        }
      );
    }, this.injectorObject);

    effect(() => {
      if (!this.accordions() || !this.summaryDivs()) {
        return;
      }
      let startupAccordion: CdkAccordionItem = this.accordions()[0];
      if (!startupAccordion) {
        return;
      }
      startupAccordion.open();
      let startupAccordionRef: ElementRef = this.summaryDivs()[0];
      setTimeout(() => {
        startupAccordionRef.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      });
    }, this.injectorObject);
  };

  newIndustrialExpansionTransaction (planetId: string): Transaction {
    var newColonization = {
      campaignId: this.race().campaignId,
      raceId: this.race()._id,
      turn: this.session.turnEdit(),
      type: this.transactionType,
      bodyId: planetId,
      quantity: 0,        // PTU (placements are positive, extraction is negative)
      amount: 0,          // actual cost (multi-month, if needed)
      locator: ''         // destination (or source)
    };
    return newColonization;
  };

  edit (populationInformation: InformationPopulation, mode: string = "Buy") {
    let transactionToEdit: Transaction;
    let transaction = this.transactionIndustrialByBodyId()[populationInformation.planetId];
    if (transaction) {
      transactionToEdit = Object.assign({}, transaction);
      mode = (transactionToEdit.quantity < 0) ? mode = "Sell" : "Buy";
    }
    else {
      transactionToEdit = this.newIndustrialExpansionTransaction(populationInformation.planetId);
    }
    let config = this.buildDataDialogConfig("Industrial Unit Adjustment", transactionToEdit);
    config.data = Object.assign(config.data, {
      populationInformation: populationInformation,
      mode: mode
    });
    this.openTransactionDialog("Industrial Unit Adjustment", transactionToEdit, IndustrialUnitEditComponent, config);
  };

  add (populationInformation: InformationPopulation) {
    this.edit(populationInformation, "Buy");
  };

  sell (populationInformation: InformationPopulation) {
    this.edit(populationInformation, "Sell");
  };

  remove (bodyId: string) {
    let transaction = this.transactionIndustrialByBodyId()[bodyId];
    let heading = "Transaction - IU " + (transaction.quantity < 0 ? "Sold" : "Emplaced");
    this.openDeleteTransactionDialog(heading, transaction);
  };
};
