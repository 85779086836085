<div>{{ pageTitle }}</div>

@if (authorizedMarshal) {
  <div><input type="checkbox" [(ngModel)]="skyMarshalMode" /> Sky Marshal Mode</div>
}

<div class="flex">
  <economic-summary-table [turn]="session.turnEdit()" [incomeTotal]="incomeTotal" [expensesTotal]="expensesTotal()" [extractedHeading]="'Economic Research:'" [extractedTotal]="total()"></economic-summary-table>

  @if (researchProject(); as researchProject) {
    <table id="_RESEARCH_" class="ml-2">
      <tr class="data">
        <td class="text-center text-1.5 ism-gold" colspan="2">Research</td>
      </tr>
      @if (skyMarshalMode) {
        <tr class="data">
          <td class="text-right">Code</td>
          <td>{{ researchProject?.code }}</td>
        </tr>
      }
      <tr class="data">
        <td class="text-right">Description</td>
        <td>{{ researchProject?.description }}</td>
      </tr>
      <tr class="data">
        <td class="text-right">Turn Started</td>
        <td>
          {{ researchProject?.turnStarted === 0 ? "Start Up" : researchProject?.turnStarted === -1 ? "On Hold" : researchProject?.turnStarted }}
        </td>
      </tr>
      @if (skyMarshalMode) {
        <tr class="data">
          <td class="text-right">Earned Points</td>
          <td>{{ researchProject?.earnedPoints }}</td>
        </tr>
        <tr class="data">
          <td class="text-right">Required Points</td>
          <td>{{ researchProject?.requiredPoints }}</td>
        </tr>
      }
      <tr class="data">
        <td class="text-right">Start Up Fee</td>
        <td>{{ researchProject?.startUpFee }}</td>
      </tr>
      <tr class="data">
        <td class="text-right">Monthly Fee</td>
        <td>{{ researchProject?.monthlyFee }}</td>
      </tr>
      <tr class="data">
        <td colspan="2">
          <div class="flex justify-center items-center">
            <div id="_Buttons" class="flex items-center justify-end">
              @if (this.canBeCancelled()) {
                <!-- change made to transaction, changing mind regarding action taken -->
                <button id="_CANCEL_" class="mx-2 px-2 btn" type="button" (click)="cancel()">Cancel</button>
              } @else if (researchProject?.turnStarted === 0 && researchProject?.earnedPoints === 0) {
                <!-- never been started, no points earned -->
                <button id="_START_" class="mx-2 px-2 btn" type="button" (click)="start()">Start</button>
              } @else if (researchProject?.turnStarted === -1) {
                <!-- started, and stopped on purpose (stuff happens) -->
                <button id="_RE-START_" class="mx-2 px-2 btn" type="button" (click)="start(0.5)">Re-Start</button>
              } @else if (researchProject?.earnedPoints >= 0) {
                <!-- started, and monthly fee is being paid -->
                <button id="_STOP_" class="mx-2 px-2 btn" type="button" (click)="stop()">Stop</button>
              }
            </div>
          </div>
        </td>
      </tr>
    </table>
  } @else {
    <div id="_NO-DATA_" class="ml-2 flex-1">No Current Economic Level Research</div>
  }
</div>

@if (theTransaction(); as theTransaction) {
  <div id="_STARTUP-DATA_">
    <div class="text-1.5 my-2 ism-gold font-bold">Startup Economic Level Research</div>
    <table>
      <thead>
        <tr>
          <th>Description</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ theTransaction.description }}</td>
          <td>{{ theTransaction.amount }} MC</td>
        </tr>
      </tbody>
    </table>
  </div>
}

@if (!hasNextLevelActive) {
  <hr class="border border-yellow-300" />
  <div id="_NO-DATA_">
    There is no economic level research available.
    @if (skyMarshalMode) {
      <button class="mx-4 btn" type="button" (click)="activateLevelResearch()">Enable Economic Level Research</button>
    }
  </div>
}

@if (history().length > 0) {
  <hr class="border border-yellow-300" />
  <div id="_HISTORICAL-DATA_" class="flex flex-col w-full">
    <div class="text-1.5 my-2 w-full ism-gold font-bold">Historic Economic Level Research</div>
    <table>
      <tr>
        @if (skyMarshalMode) {
          <th>Code</th>
        }
        <th>Description</th>
        <th>Turn Started</th>
        <th>Turn Completed</th>
        @if (skyMarshalMode) {
          <th>Earned Points</th>
          <th>Required Points</th>
        }
        <th>Start Up Fee</th>
        <th>Monthly Fee</th>
      </tr>
      @for (level of history() | orderBy: "-code"; track level) {
        <tr class="data">
          @if (skyMarshalMode) {
            <td>{{ level.code }}</td>
          }
          <td>{{ level.description }}</td>
          <td>{{ level.turnStarted }}</td>
          <td>{{ level.turnCompleted }}</td>
          @if (skyMarshalMode) {
            <td>{{ level.earnedPoints }}</td>
            <td>{{ level.requiredPoints }}</td>
          }
          <td>{{ level.startUpFee }}</td>
          <td>{{ level.monthlyFee }}</td>
        </tr>
      }
    </table>
  </div>
} @else {
  <div id="_NO-DATA_" class="my-2 w-full">There is no economic level research history.</div>
}
