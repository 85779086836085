import { Component, Injector, OnInit, Signal, WritableSignal, computed, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';

import { FleetService } from '../../services/fleet.service';
import { InformationPlanetService } from '../../services/information-planet.service';
import { InformationPopulationService } from '../../services/information-population.service';
import { ShipService } from '../../services/ship.service';
import { InformationStarSystemService } from '../../services/information-star-system.service';
import { TableService } from '../../services/table.service';
import { UtilityService } from '../../services/utility.service';

import { Fleet } from '../../interfaces/fleet';
import { Government } from '../../interfaces/government';
import { InformationPlanet } from '../../interfaces/information-planets';
import { InformationPopulation } from '../../interfaces/information-population';
import { Transaction } from '../../interfaces/transaction';
import { InformationStarSystem } from '../../interfaces/information-star-system';

import { AreYouSure2Component } from '../../shared/are-you-sure2/are-you-sure2.component';
import { EmplacementEditComponent } from '../emplacement-edit/emplacement-edit.component';
import { ExtractionEditComponent } from '../extraction-edit/extraction-edit.component';
import { TransactionBaseComponent } from '../../economics/transaction-base/transaction-base.component';
import { DialogConfig } from '@angular/cdk/dialog';

@Component({
  selector: 'colonization',
  templateUrl: './colonization.component.html'
})
export class ColonizationComponent extends TransactionBaseComponent implements OnInit {
  localInjector = inject(Injector);
  private injectionOption = { injector: this.localInjector };
  private fleetService = inject(FleetService);
  private informationPopulationService = inject(InformationPopulationService);
  private informationPlanetService = inject(InformationPlanetService);
  private shipService = inject(ShipService);
  private informationStarSystemService = inject(InformationStarSystemService);
  private tableService = inject(TableService);
  private utilityService = inject(UtilityService);

  canEdit: boolean = false;
  government: Signal<Government>;
  starSystemsRestricted: WritableSignal<InformationStarSystem[]> = signal([]);

  myPopulationInfo: Signal<InformationPopulation[]>;
  populationInformationByPlanetId: { [key: string]: InformationPopulation; } = {};
  starSystemsPopulationInfo: { [key: string]: InformationPopulation[]; } = {};
  freePopulationInfo: { [key: string]: InformationPopulation[]; } = {};

  surveyedBodyInfo: Signal<InformationPlanet[]>;
  surveyedBodyInfoByBodyId: { [key: string]: InformationPlanet; } = {};
  surveyedBodyByStarSystemNumber: { [key: string]: InformationPlanet[]; } = {};

  systemsSet: WritableSignal<string[]> = signal([]);
  freePtuByStarSystemId: { [key: string]: number; } = {};
  baseCfnH: number = 0;
  baseCfnQ: number = 0;
  ssIncome: { [key: string]: number; } = {};
  inSystemCfnPtu: { [key: string]: number; } = {};
  fleets: Signal<Fleet[]> = signal([]);
  overflowFleetsCounts: { [key: string]: number; } = {};
  excessFreightFee: Transaction;
  ptuPlaced: Signal<{ [key: string]: number; }> = signal({});
  ptuPlacedByBody: { [key: string]: number; } = {};
  cfnQUsed: number = 0;
  cfnHUsed: number = 0;
  mcAmount: { [key: string]: number; } = {};
  transactionsExtraction: WritableSignal<Transaction[]> = signal([]);
  transactionsInterstellar: WritableSignal<Transaction[]> = signal([]);
  transactionsInternalFree: WritableSignal<Transaction[]> = signal([]);
  transactionsInternal: WritableSignal<Transaction[]> = signal([]);

  constructor () {
    super();
    this.transactionType = "colonization";
    this.canEdit = this.race().readyForEconomicsTurn < this.session.turnEdit();
  };

  private determineFreePtu (informationPopulation: InformationPopulation): number {
    let ptuFree = 0;
    if (informationPopulation.pu > 400) {
      if (informationPopulation.pu >= 3200) {
        ptuFree += 100;
      }
      else if (informationPopulation.pu > 1600) {
        ptuFree += 10;
      }
      else if (informationPopulation.pu > 800) {
        ptuFree += 4;
      }
      else {
        ptuFree += 1;
      }
    }
    return ptuFree;
  };

  private assessFreightFees () {
    if (!this.excessFreightFee) {
      this.excessFreightFee = this.getColonizationTransactionBase();
      this.excessFreightFee.endTurn = this.session.turnEdit() + 1,
        this.excessFreightFee.description = 'excess freight fees';
    }

    let oldQInUse = this.excessFreightFee.qInUse;
    let oldHInUse = this.excessFreightFee.hInUse;

    // AND remove goverment FT Pool Q and H - tbd
    let excessQInUse = Math.max(this.cfnQUsed - this.baseCfnQ, 0);
    let excessHInUse = Math.max(this.cfnHUsed - this.baseCfnH, 0);

    let changed = (oldQInUse !== excessQInUse) || (oldHInUse !== excessHInUse);
    if (changed) {
      // Normal shipping cost is 1 / H & 1.5 / Q
      // Colonization is discounted / subsidized by 0.5 per H & Q
      // Any shipping OVER base capability is tripled!
      // excess fees are 2x the base rate, so 2 per H & 3 per Q, no 'rebates'
      let amount = ((2 * (excessHInUse || 0)) + (3 * (excessQInUse || 0)));
      this.excessFreightFee.qInUse = Math.max(excessQInUse, 0);
      this.excessFreightFee.hInUse = Math.max(excessHInUse, 0);
      this.excessFreightFee.amount = amount;

      this.saveTransaction(this.excessFreightFee);
    }
  };

  private summarizeTransactions (transactions: Transaction[]): { [key: string]: number; } {
    let summary: { [key: string]: number; } = { 'total': 0 };
    summary = transactions.reduce((summary, transaction) => {
      let starSystemNumber = transaction.locator.split("-")[0] as string;
      let quantity = Math.abs(transaction.quantity);
      summary['total'] = summary['total'] + quantity;
      summary[starSystemNumber] = (summary[starSystemNumber] || 0) + quantity;
      return summary;
    }, summary);
    return summary;
  };

  private groupTransactions (transactions: Transaction[]): { [key: string]: Transaction[]; } {
    let groupBase: { [key: string]: Transaction[]; } = {};
    let grouped = transactions.reduce((group, transaction) => {
      let starSystemNumber = transaction.locator.split("-")[0] as string;
      group[starSystemNumber] = (group[starSystemNumber] || []).concat(transaction);
      return group;
    }, groupBase);
    return grouped;
  };

  private getSubCategory (transaction: Transaction) {
    let category: string = 'unknown';
    if (transaction.description === 'excess freight fees') {
      category = 'Freight';
    }
    else if (transaction.quantity < 0) {
      category = 'Extraction';
    }
    else if (transaction.cfnType === 'inSystem') {
      if (transaction.qInUse === 0 && transaction.hInUse === 0) {
        category = 'Internal Free';
      }
      else {
        category = 'Internal';
      }
    }
    else {
      category = 'Interstellar';
    }
    return category;
  };

  private selectSignal (transaction: Transaction): WritableSignal<Transaction[]> {
    let category = this.getSubCategory(transaction);
    let transactionSignal: WritableSignal<Transaction[]>;
    // create structures for colonization transactions
    switch (category) {
      case 'Extraction':
        transactionSignal = this.transactionsExtraction;
        break;
      case 'Internal':
        transactionSignal = this.transactionsInternal;
        break;
      case 'Internal Free':
        transactionSignal = this.transactionsInternalFree;
        break;
      case 'Interstellar':
        transactionSignal = this.transactionsInterstellar;
        break;
      case 'Freight':
        break;
      default:
        console.log('unknown category: %s', category);
    }
    return transactionSignal;
  };

  private getColonizationTransactionBase (): Transaction {
    let newColonization = {
      campaignId: this.session.getCampaign()._id,
      raceId: this.session.getRace()._id,
      turn: this.session.turnEdit(),
      endTurn: this.session.turnEdit(),
      type: 'colonization',
      quantity: 1,        // PTU (placements are positive, extraction is negative)
      locator: '',        // destination (or source)
      description: '',
      amount: 0,          // actual cost (multi-month, if needed)
      hInUse: 0,
      qInUse: 0,
      cfnType: 'inSystem' // or interstellar
    };
    return newColonization;
  };

  extractionsByStarSystem: Signal<{ [key: string]: Transaction[]; }> = computed(() => {
    return this.groupTransactions(this.transactionsExtraction());
  });

  ptuExtracted: Signal<{ [key: string]: number; }> = computed(() => {
    return this.summarizeTransactions(this.transactionsExtraction());
  });

  interstellarByStarSystem: Signal<{ [key: string]: Transaction[]; }> = computed(() => {
    return this.groupTransactions(this.transactionsInterstellar());
  });

  ptuPlacedInterstellar: Signal<{ [key: string]: number; }> = computed(() => {
    return this.summarizeTransactions(this.transactionsInterstellar());
  });

  internalFreeByStarSystem: Signal<{ [key: string]: Transaction[]; }> = computed(() => {
    return this.groupTransactions(this.transactionsInternalFree());
  });

  ptuPlacedInternalFree: Signal<{ [key: string]: number; }> = computed(() => {
    return this.summarizeTransactions(this.transactionsInternalFree());
  });

  internalByStarSystem: Signal<{ [key: string]: Transaction[]; }> = computed(() => {
    return this.groupTransactions(this.transactionsInternal());
  });

  ptuPlacedInternal: Signal<{ [key: string]: number; }> = computed(() => {
    return this.summarizeTransactions(this.transactionsInternal());
  });

  override ngOnInit () {
    super.ngOnInit();

    this.government = toSignal(
      this.tableService.getGovernmentById(this.race().governmentId, this.race().campaignId),
      this.injectionOption
    );

    effect(() => {
      let extractionTransactions: Transaction[] = [];
      let internalTransactions: Transaction[] = [];
      let internalFreeTransactions: Transaction[] = [];
      let interstellarTransactions: Transaction[] = [];

      this.ptuPlacedByBody = {};
      this.cfnQUsed = 0;
      this.cfnHUsed = 0;

      let transactions = this.transactions();
      if (!transactions) {
        return;
      }

      transactions.forEach(transaction => {
        let ptuPlacement = true;
        let category = this.getSubCategory(transaction);
        // create structures for colonization transactions
        switch (category) {
          case 'Extraction':
            extractionTransactions.push(transaction);
            break;
          case 'Freight':
            this.excessFreightFee = transaction;
            ptuPlacement = false;
            break;
          case 'Internal':
            internalTransactions.push(transaction);
            this.cfnQUsed = this.cfnQUsed + transaction.qInUse;
            this.cfnHUsed = this.cfnHUsed + transaction.hInUse;
            break;
          case 'Internal Free':
            internalFreeTransactions.push(transaction);
            break;
          case 'Interstellar':
            interstellarTransactions.push(transaction);
            this.cfnQUsed = this.cfnQUsed + transaction.qInUse;
            this.cfnHUsed = this.cfnHUsed + transaction.hInUse;
            break;
          default:
            console.log('unknown category: %o', category);
        }
        if (ptuPlacement) {
          this.ptuPlacedByBody[transaction.bodyId] = (this.ptuPlacedByBody[transaction.bodyId] || 0) + transaction.quantity;
        }
      });

      this.transactionsExtraction.set(extractionTransactions);
      this.transactionsInternal.set(internalTransactions);
      this.transactionsInternalFree.set(internalFreeTransactions);
      this.transactionsInterstellar.set(interstellarTransactions);

      if (this.cfnQUsed > 0 || this.cfnHUsed > 0) {
        this.assessFreightFees();
      }
    }, { injector: this.localInjector, allowSignalWrites: true });

    this.ptuPlaced = computed(() => {
      let interstellar = this.ptuPlacedInterstellar();
      let internalFree = this.ptuPlacedInternalFree();
      let internal = this.ptuPlacedInternal();
      let keys = new Set([
        ...Object.keys(interstellar),
        ...Object.keys(internalFree),
        ...Object.keys(internal)
      ]);
      let consolidatedEmplacement = {} as { [key: string]: number; };
      keys.forEach(key => {
        consolidatedEmplacement[key] = (interstellar[key] || 0) + (internalFree[key] || 0) + (internal[key] || 0);
      });
      return consolidatedEmplacement;
    });

    // get all informationPopulation
    this.myPopulationInfo = toSignal<InformationPopulation[]>(
      this.informationPopulationService.getInformationPopulationForRaceId(this.race()._id).pipe(
        tap(informationPopulations => {
          informationPopulations.forEach(informationPopulation => {
            let starSystemId = informationPopulation.starSystemId;
            let freePtu = this.determineFreePtu(informationPopulation);
            informationPopulation['freePtu'] = freePtu;
            this.freePtuByStarSystemId['total'] = (this.freePtuByStarSystemId['total'] || 0) + freePtu;
            this.freePtuByStarSystemId[starSystemId] = (this.freePtuByStarSystemId[starSystemId] || 0) + freePtu;

            this.populationInformationByPlanetId[informationPopulation.planetId] = informationPopulation;

            let informationPops = (this.starSystemsPopulationInfo[starSystemId] || []);
            informationPops.push(informationPopulation);
            this.starSystemsPopulationInfo[starSystemId] = informationPops;

            if (freePtu > 0) {
              let informationPopsFreePtu = (this.freePopulationInfo[starSystemId] || []);
              informationPopsFreePtu.push(informationPopulation);
              this.freePopulationInfo[starSystemId] = informationPopsFreePtu;
            }
          });
        })
      ),
      this.injectionOption
    );

    this.informationPlanetService.getInformationPlanetsForRaceId(this.race()._id).subscribe(
      informationPlanets => {
        let systemsSet = new Set<string>();
        this.surveyedBodyByStarSystemNumber = {};
        informationPlanets.forEach(informationPlanet => {
          this.surveyedBodyInfoByBodyId[informationPlanet.bodyId] = informationPlanet;
          systemsSet.add(informationPlanet.starSystemId);

          let locatorElements = informationPlanet.locator.split("-");
          let starSystemNumber = locatorElements[0];
          let bodyArray = (this.surveyedBodyByStarSystemNumber[starSystemNumber] || []);
          bodyArray.push(informationPlanet);
          this.surveyedBodyByStarSystemNumber[starSystemNumber] = bodyArray;
        });
        this.systemsSet.set(Array.from(systemsSet));
      }
    );

    effect(() => {
      let systemsSet = this.systemsSet();
      if (!systemsSet || systemsSet.length === 0) {
        return;
      }

      this.baseCfnH = Math.floor(this.popIncomeTotal() / 5);
      this.baseCfnQ = Math.floor(this.popIncomeTotal() / 10);

      this.informationStarSystemService.getInformationStarSystemForRaceId(this.race()._id).subscribe(
        informationStarSystems => {
          let restricted = informationStarSystems.filter(
            informationStarSystem => {
              let result = systemsSet.includes(informationStarSystem.starSystemId);
              if (result) {
                let ssNumber = informationStarSystem.starSystemNumber;
                let key = "popIncome" + ssNumber;
                let income = (this.summary()[key] || {})['amount'] || 0;
                this.inSystemCfnPtu[ssNumber] = (income < 200) ? 0 : Math.floor(income / 100);
              }
              return result;
            }
          );
          this.starSystemsRestricted.set(restricted);
        }
      );
    }, this.injectionOption);

    // get all fleets
    this.fleets = toSignal<Fleet[]>(
      this.fleetService.getFreighterOverflowFleetsForRace$(this.race()._id).pipe(
        tap(fleets => {
          fleets.forEach(fleet => {
            let shipCounts = fleet.ships.map(ship => this.shipService.countItems(ship, ['H', 'Hs', 'Q', 'Qs']));
            if (shipCounts.length === 0) {
              shipCounts = [{
                'H': 0,
                'Hs': 0,
                'Q': 0,
                'Qs': 0
              }];
            }
            fleet['totalCounts'] = this.utilityService.sumObjectsByKey(...shipCounts);
          });
          let overflowFleetsCounts = fleets.map(fleet => fleet['totalCounts']);
          this.overflowFleetsCounts = this.utilityService.sumObjectsByKey(...overflowFleetsCounts);
        })
      ),
      this.injectionOption
    );
  };

  private openExtractionDialog (informationStarSystem: InformationStarSystem, transaction: Transaction) {
    const dialogConfig: DialogConfig<unknown> = this.buildDataDialogConfig(
      "Colonization Transaction: Extraction", transaction
    );
    delete dialogConfig.width;
    delete dialogConfig.height;

    dialogConfig.data = Object.assign(
      dialogConfig.data,
      {
        starSystemNumber: informationStarSystem.starSystemNumber,
        populationInformationMap: this.populationInformationByPlanetId,
      }
    );

    this.openTransactionDialog(
      "Colonization Transaction: Extraction",
      transaction,
      ExtractionEditComponent,
      dialogConfig
    );
  };

  createExtraction (informationStarSystem: InformationStarSystem) {
    var newExtraction = this.getColonizationTransactionBase();
    newExtraction.quantity = -1;
    newExtraction.description = informationStarSystem.starSystemId;
    this.openExtractionDialog(informationStarSystem, newExtraction);
  };

  editExtraction (informationStarSystem: InformationStarSystem, extraction: Transaction) {
    this.openExtractionDialog(informationStarSystem, extraction);
  };

  private openEmplacementDialog (
    informationStarSystem: InformationStarSystem,
    transaction: Transaction
  ) {
    const { starSystemId, starSystemNumber } = informationStarSystem;
    const { bodyId = '', quantity = 0 } = transaction;

    let dialogConfig: DialogConfig<unknown> = this.buildDataDialogConfig(
      "Colonization Transaction: Emplacement", transaction
    );
    dialogConfig.data = Object.assign(
      dialogConfig.data,
      {
        starSystemId,
        starSystemNumber,
        availablePtu:
          (this.freePtuByStarSystemId['total'] || 0) +
          (this.ptuExtracted()['total'] || 0) -
          (this.ptuPlaced()['total'] || 0),
        ptuFromLocal:
          (this.ptuExtracted()[starSystemNumber] || 0) +
          (this.freePtuByStarSystemId[starSystemId] || 0),
        ptuPlacedInternalFree: this.ptuPlacedInternalFree()[starSystemNumber] || 0,
        ptuPlacedInternal: this.ptuPlacedInternal()[starSystemNumber] || 0,
        inSystemCfnPtu: this.inSystemCfnPtu[starSystemNumber] || 0,
        surveyedBodies: this.surveyedBodyByStarSystemNumber[starSystemNumber] || [],
        populationInformationMap: this.populationInformationByPlanetId,
        ptuPlacedByBody: this.ptuPlacedByBody,
        shippingUsage: {
          baseCfnQ: this.baseCfnQ,
          excessCfnQ: this.overflowFleetsCounts['Q'] || 0,
          cfnQUsed: this.cfnQUsed,
          baseCfnH: this.baseCfnH,
          excessCfnH: this.overflowFleetsCounts['H'] || 0,
          cfnHUsed: this.cfnHUsed['total'] || 0
        }
      }
    );

    this.openTransactionDialog(
      "Colonization Transaction: Emplacement",
      transaction,
      EmplacementEditComponent,
      dialogConfig
    );
  };

  createColonization (informationStarSystem) {
    let newColonizationToEdit = this.getColonizationTransactionBase();
    newColonizationToEdit.endTurn = this.session.turnEdit() + 1,
      newColonizationToEdit.description = informationStarSystem.starSystemId;
    this.openEmplacementDialog(informationStarSystem, newColonizationToEdit);
  };

  editTransaction (informationStarSystem: InformationStarSystem, transaction: Transaction) {
    this.openEmplacementDialog(informationStarSystem, transaction);
  };

  deleteColonization (transaction: Transaction) {
    let dialogConfig: DialogConfig<unknown> = this.buildDataDialogConfig(
      "Colonization Transaction: " + (transaction.quantity < 0 ? "Extraction" : "Emplacement"), transaction
    );

    this.openDeleteTransactionDialog(
      "Colonization Transaction",
      transaction,
      AreYouSure2Component,
      dialogConfig
    );
  };
};
