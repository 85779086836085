import { Component, Injector, OnInit, Signal, WritableSignal, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';

import { ConstructionEditComponent } from '../construction-edit/construction-edit.component';
import { TransactionBaseComponent } from '../../economics/transaction-base/transaction-base.component';

import { FleetService } from '../../services/fleet.service';
import { ShipClassService } from '../../services/ship-class.service';
import { ShipService } from '../../services/ship.service';

import { Ship } from '../../interfaces/ship';
import { Transaction } from '../../interfaces/transaction';
import { Fleet } from '../../interfaces/fleet';
import { ShipClass } from '../../interfaces/ship-class';
import { StarSystem } from '../../interfaces/star-system';
import { StarSystemService } from '../../services/star-system.service';

@Component({
  selector: 'construction',
  templateUrl: './construction.component.html',
})
export class ConstructionComponent extends TransactionBaseComponent implements OnInit {
  injector = inject(Injector);
  private fleetService = inject(FleetService);
  private shipClassService = inject(ShipClassService);
  private shipService = inject(ShipService);
  private starSystemService = inject(StarSystemService);

  pageTitle: string = "Construction Projects";
  fleets: Signal<Fleet[]>;
  hashEconomicData: { [key: string]: Transaction; } = {};
  shipyardsById: { [key: string]: Ship; } = {};
  shipyardsByStarSystemId: { [key: string]: Ship[]; } = {};
  fleetsById: { [key: string]: Fleet; } = {};
  allShipYardKeys: { [key: string]: string[]; } = {};
  nextShipYardKeys: { [key: string]: string; } = {};
  hostStarSystems: WritableSignal<StarSystem[]> = signal([]);
  shipClasses: Signal<ShipClass[]>;

  shipClassHash: any;
  hullsPerMonth: number;
  canEdit: boolean = false;

  constructor () {
    super();
    this.session.iAmBusy();

    this.transactionType = "construction";
    this.hullsPerMonth = 10 + (2 * this.race().techLevel);
    this.canEdit = this.race().readyForEconomicsTurn < this.session.turnEdit();

    this.updatedTransaction.subscribe(updatedTransaction => {
      // A New transaction will be added by _id
      // An Edited transaction will be updated by _id
      let key = updatedTransaction.turn + '-' + updatedTransaction.locator + '-' + updatedTransaction.sequence;
      this.hashEconomicData[key] = updatedTransaction;
      this.processConstructionTransaction(key, updatedTransaction);
    });

    this.deletedTransaction.subscribe(deletedTransaction => {
      // A deleted transaction will be removed by _id
      // the revised transactions will update the transactions signal
      this.session.setNotifyMessage('Construction Job Transaction Deleted');
      let key = deletedTransaction.turn + '-' + deletedTransaction.locator + '-' + deletedTransaction.sequence;
      delete this.hashEconomicData[key];
    });
  };

  override ngOnInit (): void {
    super.ngOnInit();

    let constructionData = toSignal<Transaction[]>(
      this.transactionService.getConstructionData$(this.race()._id),
      { injector: this.injector }
    );

    let shipyards = toSignal<Ship[]>(
      this.shipService.getShipYardsForRace$(this.race()),
      { injector: this.injector }
    );

    this.fleets = toSignal<Fleet[]>(
      this.fleetService.getFleetsForRaceId$(this.race()._id),
      { injector: this.injector }
    );

    this.shipClasses = toSignal<ShipClass[]>(
      this.shipClassService.getShipClassesForRace$(this.race()),
      { injector: this.injector }
    );
    this.session.iAmNotBusy();

    effect(() => {
      if (!constructionData() || !shipyards() || !this.fleets() || !this.shipClasses()) {
        return;
      }

      this.session.iAmBusy();
      this.hashEconomicData = this.transactionService.processConstructionTransactions(constructionData());
      this.processTransactionData(this.hashEconomicData);

      this.shipyardsById = shipyards().reduce((ships, ship) => (ships[ship._id] = ship, ships), {});
      let shipyardIds = Object.keys(this.shipyardsById);

      this.fleetsById = this.fleets().reduce((hash, fleet) => {
        for (const shipId of fleet.shipIds) {
          if (shipyardIds.includes(shipId)) {
            let starSystemId = fleet.starSystemId;
            let ships = this.shipyardsByStarSystemId[starSystemId] || [];
            ships.push(this.shipyardsById[shipId]);
            this.shipyardsByStarSystemId[starSystemId] = ships;
            hash[shipId] = fleet;
          }
        }
        hash[fleet._id] = fleet;
        return hash;
      }, {});
      let starSystemIds = Object.keys(this.shipyardsByStarSystemId);

      this.shipClassHash = this.shipClasses().reduce((hash, shipClass) => (hash[shipClass._id] = shipClass, hash), {});

      this.starSystemService.getAllStarSystemsForCampaign(this.race().campaignId).pipe(
        map(starSystems => starSystems.filter(starSystem => starSystemIds.includes(starSystem._id))),
      ).subscribe(
        starSystems => this.hostStarSystems = signal<StarSystem[]>(starSystems)
      );

      this.session.iAmNotBusy();
    }, { injector: this.injector });
  };

  processConstructionTransaction (key: string, transaction: Transaction) {
    let turnEdit = this.session.turnEdit();
    let lastTurnComplete = this.race().economicsCompleteTurn;
    let [startTurnStr, shipId, shipYardNumber, sequenceStr] = key.split('-');
    let startTurn = parseInt(startTurnStr);
    let sequence = parseInt(sequenceStr);

    let shipYardKey = shipId + '-' + shipYardNumber;
    let nextShipYardKey_base = turnEdit + '-' + shipId + '-' + shipYardNumber + '-';
    let nextSequence = sequence + transaction.size - ((turnEdit - Math.max(lastTurnComplete, startTurn)) * this.hullsPerMonth);
    let shipYardKeys = this.allShipYardKeys[shipYardKey] || [];

    if (startTurn < turnEdit) {
      if (nextSequence > 1) {
        shipYardKeys[0] = key;
      }
      if (nextSequence < 1) {
        nextSequence = 1;
      }
    }
    else if (startTurn == turnEdit && sequence == 1) {
      shipYardKeys[0] = key;
      nextSequence = transaction.sequence + transaction.size;
    }
    else if (startTurn == turnEdit) {
      if (!shipYardKeys.includes(key)) {
        shipYardKeys.push(key);
      }
      nextSequence = transaction.sequence + transaction.size;
    }
    else {
      //this is the top limiter - need the lowest of these...
      nextSequence = 99;
    }

    this.allShipYardKeys[shipYardKey] = shipYardKeys;

    if (nextSequence <= 0) {
      nextSequence = 1;
    }
    if (nextSequence <= this.hullsPerMonth) {
      this.nextShipYardKeys[shipYardKey] = nextShipYardKey_base + nextSequence;
    }
    else {
      this.nextShipYardKeys[shipYardKey] = 'notyet';
    }
  };

  processTransactionData (transactionsByKey: { [key: string]: Transaction; }) {
    this.allShipYardKeys = {};
    let allKeys = Object.keys(transactionsByKey);
    for (const key of allKeys) {
      let transaction = transactionsByKey[key];
      this.processConstructionTransaction(key, transaction);
    }
  };

  createConstructionTransaction (startTurn: number, shipId: string, shipyardId: string, sequence: number): Transaction {
    return {
      campaignId: this.session.getCampaign()._id,
      raceId: this.race()._id,
      turn: startTurn,
      type: 'construction',
      quantity: 1,
      locator: shipId + '-' + shipyardId,
      sequence: sequence,
      amount: 0
    };
  };

  newOrEdit (key: string) {
    let constructionTransaction = this.hashEconomicData[key];
    let [startTurnStr, shipId, shipYardNumber, sequenceStr] = key.split('-');

    if (!constructionTransaction) {
      let startTurn = parseInt(startTurnStr);
      let sequence = parseInt(sequenceStr);
      constructionTransaction = this.createConstructionTransaction(startTurn, shipId, shipYardNumber, sequence);
    }

    let ship = this.shipyardsById[shipId];
    let shipYardFleet = this.fleetsById[shipId];
    if (!shipYardFleet) {
      shipYardFleet = this.fleets().find(fleet => (fleet.shipIds.indexOf(shipId) > -1));
    }

    let starSystemId = shipYardFleet.starSystemId;
    let locationHex = shipYardFleet.locationHex;
    let filteredFleets = this.fleets().filter(
      function (fleet) {
        return ((fleet.starSystemId === starSystemId) && (fleet.locationHex === locationHex || fleet.locationHex === "0101"));
      }
    );

    let config = this.buildDataDialogConfig("Construction Job", constructionTransaction);
    config.data = Object.assign(config.data, {
      key: key,
      techLevel: this.race().techLevel,
      shipName: ship.shipName,
      shipClasses: this.shipClasses(),
      fleets: filteredFleets,
    });

    this.openTransactionDialog("Construction Transaction", constructionTransaction, ConstructionEditComponent, config);
  };

  delete (key: string) {
    let transaction = this.hashEconomicData[key];
    this.openDeleteTransactionDialog("Construction Transaction", transaction);
  };
};
