import { Component } from '@angular/core';

import { DialogHandlerComponent } from '../../shared/dialog-handler/dialog-handler.component';

import { Transaction } from '../../interfaces/transaction';

@Component({
  selector: 'app-industrial-unit-edit',
  templateUrl: './industrial-unit-edit.component.html',
})
export class IndustrialUnitEditComponent extends DialogHandlerComponent {
  title: string;
  mode: string;
  min: number = 0;
  max: number = 0;
  populationInformation: { [key: string]: any; };
  industrialUnitTransaction: Transaction;

  setMode (mode: string) {
    this.mode = mode;
    this.min = 1;
    this.max = this.populationInformation['maxIu'] - this.populationInformation['iu'];
    if (mode === "Sell") {
      if ((this.populationInformation['iu'] || 0) === 0) {
        this.min = 0;
      }
      this.max = this.populationInformation['iu'] || 0;
      this.industrialUnitTransaction.quantity = -this.industrialUnitTransaction.quantity;
      this.industrialUnitTransaction.amount = -this.industrialUnitTransaction.amount;
    }
  };

  constructor () {
    super();
    this.populationInformation = this.data['populationInformation'];
    this.industrialUnitTransaction = this.data['document'] as Transaction;
    this.setMode(this.data['mode']);
    this.title = this.data['documentName'] + " for " + this.populationInformation['locator'];
    console.log(this);
  };

  calcCost () {
    this.industrialUnitTransaction.amount = this.industrialUnitTransaction.quantity * 30;
  };
  override save () {
    if (this.mode === "Sell") {
      this.industrialUnitTransaction.quantity = -this.industrialUnitTransaction.quantity;
      this.industrialUnitTransaction.amount = this.industrialUnitTransaction.quantity * 30;
    }
    this.dialogRef.close({
      documentName: "Transaction",
      // key: this.key,
      document: this.industrialUnitTransaction
    });
  };
};
