<edit-dialog (action)="signalAction($event)" [title]="title" class="flex flex-col ism-black">
  <ng-container edit-body>
    <div class="modal-center flex flex-col items-center">
      <div class="w-full px-2 flex items-center justify-between text-black bg-yellow-100 border-gray-700 border-solid border-1">
        <div class="flex-1"></div>
        <div class="">Star System: {{ starSystemNumber }}</div>
        <div class="flex-1"></div>
      </div>

      <gray-label-wrapper [fieldLabel]="'Destination Planet / Moon'" [fieldName]="'body'" class="w-full text-black flex items-center">
        <select class="px-2 form-select w-fit" [(ngModel)]="selectedInformationPopulation" required>
          @for (population of populationInformation | orderBy: "locator"; track population) {
            <option [ngValue]="population">{{ population.locator + " (" + ((populationInformationMap[population.planetId] || {}).pu || 0) + "/" + ((populationInformationMap[population.planetId] || {}).maxPu || 0) + ")" }}</option>
          }
        </select>
      </gray-label-wrapper>

      @if (selectedInformationPopulation()) {
        <gray-label-wrapper [fieldLabel]="'Population Change'" [fieldName]="'change'" class="w-full text-black flex items-center">
          <div class="">{{ selectedInformationPopulation().pu }}:{{ oldPtuOverage }} to {{ newPu }}:{{ newPtuOverage }}</div>
        </gray-label-wrapper>

        <gray-label-wrapper [fieldLabel]="'Population Transport Unit'" [fieldName]="'ptu'" class="w-full text-black flex items-center">
          <input class="px-2 form-input w-fit" type="number" min="1" max="999" [(ngModel)]="selectedPtu" required />
        </gray-label-wrapper>
      }
    </div>
  </ng-container>
</edit-dialog>
