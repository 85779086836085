<div #industrialExpansion class="iBTTiny:mx-auto md:m-0 iBTTiny:w-11/12 md:w-full flex flex-col">
  <div class="font-bold text-1.5">Industrial Units (IU)</div>

  <div class="my-2 flex items-start">
    <economic-summary-table [turn]="session.turnEdit()" [incomeTotal]="incomeTotal" [expensesTotal]="expensesTotal()" [extractedHeading]="'Industrial Changes:'" [extractedTotal]="total()"></economic-summary-table>
  </div>

  <fieldset id="_show-all_">
    <div class="my-2 divide-y">
      <div id="_item-01_" class="relative flex items-center py-2">
        <div id="_checkbox_" class="flex h-10 items-center">
          <input id="show_all" name="showAll" type="checkbox" [(ngModel)]="showAll" class="h-8 w-8 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
        </div>
        <div id="_fieldLabel_" class="px-2 min-w-0 flex-1 text-sm leading-6">
          <label for="show_all" class="select-none font-medium text-1.25 ism-white">Show All Populations</label>
        </div>
      </div>
    </div>
  </fieldset>

  <div class="my-2 flex flex-col ism-white overlay-dark-20">
    <cdk-accordion class="w-full ism-accordion ism-accordion-border scrollbar-none data-odd-dark">
      @for (informationStarSystem of starSystemsWithPopulations() | orderBy: "starSystemNumber"; track $index) {
        <cdk-accordion-item #accordionItem="cdkAccordionItem" class="ism-accordion-item ism-accordion-border" role="button" tabindex="0" [attr.id]="'accordion-header-' + $index" [attr.aria-controls]="'accordion-body-' + $index" [attr.aria-description]="'Multiple System Display'">
          <!-- Summary -->
          <div #accordionSummary class="flex items-center justify-between text-1.25 font-bold text-center" (click)="accordionItem.toggle()" [scrollToTop]="accordionSummary" [onTriggerOnly]="true" [triggered]="accordionItem.expanded">
            <table class="w-full border-none">
              <tr class="data-even-dark">
                <td class="w-1/5 m-0 p-0 border-none">Star System: {{ informationStarSystem.starSystemNumber }}</td>
                <td class="border-none">
                  <chevron-toggle [isOpen]="accordionItem.expanded"></chevron-toggle>
                </td>
              </tr>
            </table>
          </div>

          <!-- Details -->
          <div class="w-full flex flex-col" role="region" [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-sub-body-' + $index" [attr.aria-labelledby]="'accordion-header-' + $index">
            <table class="ism-white border-none">
              <tr class="data-odd-dark">
                <th class="ism-gold">Locator</th>
                @if (canEdit) {
                  <th class="ism-gold text-right">PU</th>
                  <th class="ism-gold text-right">IU</th>
                }
                <th class="ism-gold text-right">Max IU</th>
                <th class="ism-gold text-right">Sold</th>
                <th class="ism-gold text-right">Added</th>
                <th class="ism-gold text-right">Total</th>
                @if (canEdit) {
                  <th class="ism-gold text-center">Action</th>
                }
              </tr>
              @for (informationPopulation of starSystemsPopulationInfo[informationStarSystem.starSystemId] | orderBy: "locator"; track $index) {
                @if (showAll || (transactionIndustrialByBodyId()[informationPopulation.planetId]?.quantity || 0) !== 0) {
                  <tr class="data-odd-dark">
                    <td class="">{{ informationPopulation.locator }}</td>
                    @if (canEdit) {
                      <td class="text-right">{{ informationPopulation.pu }}</td>
                      <td class="text-right">{{ informationPopulation.iu }}</td>
                    }
                    <td class="text-right">{{ informationPopulation.pu / 2 | number: "1.0-0" }}</td>
                    <td class="text-right">
                      @if ((transactionIndustrialByBodyId()[informationPopulation.planetId]?.quantity || 0) < 0) {
                        {{ -transactionIndustrialByBodyId()[informationPopulation.planetId]?.quantity || 0 }}
                      }
                    </td>
                    <td class="text-right">
                      @if ((transactionIndustrialByBodyId()[informationPopulation.planetId]?.quantity || 0) > 0) {
                        {{ transactionIndustrialByBodyId()[informationPopulation.planetId]?.quantity || 0 }}
                      }
                    </td>
                    <td class="text-right">
                      @if ((transactionIndustrialByBodyId()[informationPopulation.planetId]?.amount || 0) !== 0) {
                        {{ transactionIndustrialByBodyId()[informationPopulation.planetId]?.amount || 0 }} MC
                      }
                    </td>
                    @if (canEdit) {
                      <td>
                        <div class="flex justify-center">
                          @if ((transactionIndustrialByBodyId()[informationPopulation.planetId]?.quantity || 0) === 0) {
                            @if (informationPopulation.iu > 0) {
                              <button class="btn btn-sm btn-primary" (click)="sell(informationPopulation)">
                                <ism-svg [name]="'minus'"></ism-svg>
                              </button>
                            }
                            @if (informationPopulation.iu < informationPopulation.pu / 2) {
                              <button class="btn btn-sm btn-primary" (click)="add(informationPopulation)">
                                <ism-svg [name]="'plus'"></ism-svg>
                              </button>
                            }
                          }
                          @if ((transactionIndustrialByBodyId()[informationPopulation.planetId]?.quantity || 0) !== 0) {
                            <button class="btn btn-sm btn-primary" (click)="remove(informationPopulation.planetId)">
                              <ism-svg [name]="'trashcan'"></ism-svg>
                            </button>
                            <button class="btn btn-sm btn-primary" (click)="edit(informationPopulation)">
                              <ism-svg [name]="'pencilSquare'"></ism-svg>
                            </button>
                          }
                        </div>
                      </td>
                    }
                  </tr>
                }
              }
            </table>
          </div>
        </cdk-accordion-item>
      }
    </cdk-accordion>
  </div>
</div>
