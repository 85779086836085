import { Component, inject } from '@angular/core';

import { TransactionService } from '../../services/transaction.service';

import { DialogHandlerComponent } from '../../shared/dialog-handler/dialog-handler.component';

import { InformationPlanet } from '../../interfaces/information-planets';
import { InformationPopulation } from '../../interfaces/information-population';
import { StarSystem } from '../../interfaces/star-system';
import { Transaction } from '../../interfaces/transaction';

@Component({
  selector: 'emplacement-edit',
  templateUrl: './emplacement-edit.component.html',
})
export class EmplacementEditComponent extends DialogHandlerComponent {
  private transactionService = inject(TransactionService);
  colonizationTransaction: Transaction;
  title: string;
  originalBodyId: string = undefined;
  originalQuantity: number = 0;
  starSystemNumber: string;
  availablePtu: number;
  ptuFromLocal: number;
  ptuPlacedInternalFree: number;
  ptuPlacedInternal: number;
  inSystemCfnPtu: number;
  populationInformationMap: { [key: string]: InformationPopulation; } = {};
  surveyedBodies: InformationPlanet[] = [];
  ptuPlacedByBody: { [key: string]: number; } = {};
  shippingUsage: { [key: string]: number; } = {};

  mode: number;
  modeText: string;
  modePtuMax: number;
  selectedInformationPlanet: InformationPlanet | undefined;
  availableQ: number = 0;
  availableH: number = 0;

  maxPtu: number;
  starSystems: StarSystem[];
  starSystem: StarSystem;
  hMultiplier: number = 0;
  disabled: string | boolean;
  turnsInTransit: number = 1;
  useFreePlacement: boolean = false;

  constructor () {
    super();
    this.colonizationTransaction = this.data.document as Transaction;
    this.title = this.data['documentName'];
    let bias = this.data['bias'] || 1;
    this.modeText = (bias === -1) ? 'Extraction' : 'Colonize';

    this.starSystemNumber = this.data['starSystemNumber'];
    this.availablePtu = this.data['availablePtu'];
    this.ptuFromLocal = this.data['ptuFromLocal'];
    this.ptuPlacedInternalFree = this.data['ptuPlacedInternalFree'];
    this.ptuPlacedInternal = this.data['ptuPlacedInternal'];
    this.inSystemCfnPtu = this.data['inSystemCfnPtu'];
    this.shippingUsage = this.data['shippingUsage'];

    this.populationInformationMap = this.data['populationInformationMap'];
    this.surveyedBodies = this.data['surveyedBodies'];
    this.ptuPlacedByBody = this.data['ptuPlacedByBody'];

    this.originalBodyId = this.colonizationTransaction.bodyId || '';

    if (this.colonizationTransaction.quantity > 0 && this.colonizationTransaction.cfnType === 'inSystem') {
      this.originalQuantity = this.colonizationTransaction.quantity;
      this.ptuFromLocal += this.colonizationTransaction.quantity;
      if (this.colonizationTransaction.qInUse === 0 && this.colonizationTransaction.hInUse === 0) {
        this.ptuPlacedInternalFree -= this.colonizationTransaction.quantity;
      }
      else {
        this.ptuPlacedInternal -= this.colonizationTransaction.quantity;
      }
    }

    if (this.ptuFromLocal > 0) {
      if (this.ptuPlacedInternalFree < this.inSystemCfnPtu) {
        this.mode = 1;
        this.modeText = "Free placement via in-system CFN";
        this.modePtuMax = Math.min(this.availablePtu + this.colonizationTransaction.quantity, this.inSystemCfnPtu - this.ptuPlacedInternalFree + this.colonizationTransaction.quantity);
      }
      else {
        this.mode = 2;
        this.modeText = "Placement via in-system CFN";
        this.modePtuMax = Math.min(this.availablePtu, this.ptuFromLocal - this.ptuPlacedInternalFree - this.ptuPlacedInternal + this.colonizationTransaction.quantity);
      }
    }
    else {
      this.mode = 3;
      this.modeText = "Placement via CFN";
      this.modePtuMax = this.availablePtu;
    }

    this.turnsInTransit = this.colonizationTransaction.endTurn - this.colonizationTransaction.turn;
    this.availableQ = this.shippingUsage['baseCfnQ'] + this.shippingUsage['excessCfnQ'] - this.shippingUsage['cfnQUsed'] + this.colonizationTransaction['qInUse'];
    this.availableH = this.shippingUsage['baseCfnH'] + this.shippingUsage['excessCfnH'] - this.shippingUsage['cfnHUsed'] + this.colonizationTransaction['hInUse'];

    if (this.colonizationTransaction.bodyId) {
      this.selectedInformationPlanet = this.surveyedBodies.find(body => body.bodyId === this.colonizationTransaction.bodyId);
      this.setSelectedInformationPlanet();
    }

    if (this.ptuFromLocal >= (this.ptuPlacedInternal + this.ptuPlacedInternalFree + this.colonizationTransaction.quantity)) {
      this.colonizationTransaction.cfnType = 'inSystem';
      this.useFreePlacement = (this.inSystemCfnPtu >= (this.ptuPlacedInternalFree + this.colonizationTransaction.quantity));
      if (this.useFreePlacement) {
        this.colonizationTransaction.qInUse = 0;
        this.colonizationTransaction.hInUse = 0;
      }
    }
    else {
      this.useFreePlacement = false;
      this.colonizationTransaction.cfnType = 'interstellar';
    }
  };

  setSelectedInformationPlanet () {
    let originalQuantity = 0;
    if (this.originalBodyId === this.selectedInformationPlanet.bodyId) {
      originalQuantity = this.originalQuantity;
    }
    this.colonizationTransaction.bodyId = this.selectedInformationPlanet.bodyId;
    this.colonizationTransaction.locator = this.selectedInformationPlanet.locator;
    this.colonizationTransaction.informationPopulationId = this.populationInformationMap[this.selectedInformationPlanet.bodyId]._id;
    let hd = this.selectedInformationPlanet.habitDifference;
    // Benign
    this.hMultiplier = 1;
    // Extreme
    if (hd === 18) {
      this.hMultiplier = 5;
    }
    // Desolate
    else if (hd === 12) {
      this.hMultiplier = 4;
    }
    // Hostile
    else if (hd === 10) {
      this.hMultiplier = 3;
    }
    // Harsh
    else if (hd < 10 && hd > 2) {
      this.hMultiplier = 2;
    }

    let maxPu = 0;
    let maxPtu = 0;
    if (this.selectedInformationPlanet.locator.indexOf('(A)') > -1) {
      if (this.selectedInformationPlanet.radius) {
        let hexes = Math.ceil(this.selectedInformationPlanet.radius / 12) * 6;
        // population centers allowed ===  5 per hex === 5 * hexes
        let popCenters = 5 * hexes;
        // max ptu per <population center> === 16 ptu (outpost)
        maxPu = popCenters * 16;
        maxPtu = maxPu;
      }
    }
    else {
      maxPu = this.populationInformationMap[this.selectedInformationPlanet.bodyId].maxPu;
      maxPtu = this.transactionService.puToPtu(maxPu);
    }
    let bodyMax = maxPtu
      - this.populationInformationMap[this.selectedInformationPlanet.bodyId].ptu
      - (this.ptuPlacedByBody[this.selectedInformationPlanet.bodyId] || 0)
      + originalQuantity;
    let maxPtuByH = Math.floor(this.availableH / this.hMultiplier / 10);
    let maxPtuByQ = Math.floor(this.availableQ / 10);
    if (this.mode === 3) {
      this.maxPtu = Math.min(bodyMax, this.modePtuMax, maxPtuByQ, maxPtuByH);
    }
    else {
      this.maxPtu = Math.min(bodyMax, this.modePtuMax);
    }

    if (this.colonizationTransaction.quantity > this.maxPtu) {
      this.colonizationTransaction.quantity = this.maxPtu >= 0 ? this.maxPtu : 0;
    }

    this.updatePtu();
  };

  updatePtu () {
    this.useFreePlacement = (this.inSystemCfnPtu >= (this.ptuPlacedInternalFree + this.colonizationTransaction.quantity));
    this.colonizationTransaction.cfnType = 'inSystem';
    if (this.useFreePlacement) {
      this.colonizationTransaction.qInUse = 0;
      this.colonizationTransaction.hInUse = 0;
    }
    else {
      if (this.ptuFromLocal < (this.ptuPlacedInternalFree + this.ptuPlacedInternal + this.colonizationTransaction.quantity)) {
        this.colonizationTransaction.cfnType = 'interstellar';
      }

      // 15.06.03b table interpretation
      this.colonizationTransaction.qInUse = this.colonizationTransaction.quantity * 10;
      this.colonizationTransaction.hInUse = this.colonizationTransaction.quantity * this.hMultiplier * 10;
    }

    // 15.06.03b table interpretation
    this.colonizationTransaction.amount = ((this.colonizationTransaction.quantity || 0) * (5 * (2 + this.hMultiplier))
      + ((this.turnsInTransit || 1) * ((0.5 * this.colonizationTransaction.hInUse) + this.colonizationTransaction.qInUse)));
  };

  override save () {
    this.dialogRef.close({
      documentName: "Transaction",
      // key: this.key,
      document: this.colonizationTransaction
    });
  };
};
