import { Component, Signal, signal } from '@angular/core';

import { Fleet } from '../../interfaces/fleet';
import { ShipClass } from '../../interfaces/ship-class';
import { Transaction } from '../../interfaces/transaction';
import { DialogHandlerComponent } from '../../shared/dialog-handler/dialog-handler.component';

@Component({
  selector: 'app-construction-edit',
  templateUrl: './construction-edit.component.html',
})
export class ConstructionEditComponent extends DialogHandlerComponent {
  hullsPerMonth: number;
  title: string = 'Construction Job Edit';
  key: string;
  constructionTransaction: Transaction;
  shipName: string;
  shipYardNumber: string;
  shipClasses: ShipClass[];
  fleets: Signal<Fleet[]>;
  shipClass: ShipClass;
  disabled: boolean;

  constructor () {
    super();
    this.hullsPerMonth = 10 + (2 * this.data['techLevel']);

    this.key = this.data['key'] as string;
    this.constructionTransaction = this.data.document as Transaction;
    this.shipName = this.data['shipName'];
    this.shipClasses = this.data['shipClasses'] as ShipClass[];
    this.fleets = signal<Fleet[]>(this.data['fleets'] as Fleet[]);

    let [startTurnStr, shipId, shipYardNumber, sequenceStr] = this.key.split('-');
    this.shipYardNumber = shipYardNumber;

    if (this.constructionTransaction.shipClassId) {
      this.shipClass = this.shipClasses.find(shipClass => shipClass._id === this.constructionTransaction.shipClassId);
      this.disabled = (this.shipClass && this.constructionTransaction.size < this.shipClass.actualSize);
    }
  };

  setShipClass () {
    if (this.shipClass) {
      this.constructionTransaction.shipClassId = this.shipClass._id;
      this.constructionTransaction.amount = this.shipClass.buildCost;
      this.constructionTransaction.size = this.shipClass.actualSize;
    }
  };

  override save () {
    this.dialogRef.close({
      documentName: "Transaction",
      key: this.key,
      document: this.constructionTransaction
    });
  };
};
