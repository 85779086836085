import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authenticated } from '../auth/auth.guard';

import { ColonizationComponent } from './colonization/colonization.component';
import { ConstructionComponent } from './construction/construction.component';
import { EconomicLevelResearchComponent } from './economic-level-research/economic-level-research.component';
import { IndustrialExpansionComponent } from './industrial-expansion/industrial-expansion.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { OtherExpensesComponent } from './other-expenses/other-expenses.component';
import { TechItemDevelopmentComponent } from './tech-item-development/tech-item-development.component';
import { TroopRecruitingComponent } from './troop-recruiting/troop-recruiting.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [authenticated],
    children: [
      {
        path: 'maintenance',
        component: MaintenanceComponent,
      },
      {
        path: 'construction',
        component: ConstructionComponent,
      },
      {
        path: 'industrial-expansion',
        component: IndustrialExpansionComponent,
      },
      {
        path: 'colonization',
        component: ColonizationComponent,
      },
      {
        path: 'troops',
        component: TroopRecruitingComponent,
      },
      {
        path: 'economic-research',
        component: EconomicLevelResearchComponent,
      },
      {
        path: 'project-research',
        component: TechItemDevelopmentComponent,
      },
      {
        path: 'other-expenses',
        component: OtherExpensesComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExpensesRoutingModule { }
