import { Component, effect, inject, OnInit, Signal, signal, WritableSignal } from '@angular/core';

import { TransactionBaseComponent } from '../../economics/transaction-base/transaction-base.component';

import { authorizedMarshal } from '../../auth/auth.guard';

import { TableService } from '../../services/table.service';
import { ProjectService } from '../../services/project.service';

import { Project } from '../../interfaces/project';
import { Transaction } from '../../interfaces/transaction';
import { map } from 'rxjs';

@Component({
  selector: 'tech-item-development',
  templateUrl: './tech-item-development.component.html',
})
export class TechItemDevelopmentComponent extends TransactionBaseComponent implements OnInit {
  pageTitle: string = "Tech Item Development";
  tableService = inject(TableService);
  projectService = inject(ProjectService);
  authorizedMarshal: boolean = authorizedMarshal() || false;
  skyMarshalMode: boolean = false;

  // allDevelopmentProjects: Signal<Project[]>;
  history: WritableSignal<Project[]> = signal([]);
  started: WritableSignal<Project[]> = signal([]);
  notStarted: WritableSignal<Project[]> = signal([]);
  transactionByCode: WritableSignal<{ [key: string]: Transaction; }> = signal({});

  constructor () {
    super();
    this.transactionType = "techItem";
    // this.authorizedMarshal = authorizedMarshal();
    // this.allDevelopmentProjects = this.projectService.getProjectItemsForRace(this.race()._id);

    effect(() => {
      let transactionByCode: { [key: string]: Transaction; } = {};
      let transactions = this.transactions();
      console.log("building transactions: ", transactions);
      if (transactions != null && transactions.length > 0) {
        transactionByCode = transactions.reduce((hash, transaction: Transaction) => {
          hash[transaction.locator] = transaction;
          return hash;
        }, transactionByCode);
        console.log("hashing transactionByCode: ", transactionByCode);
      }
      this.transactionByCode.set(transactionByCode);
    }, { allowSignalWrites: true });
  };

  override ngOnInit (): void {
    super.ngOnInit();

    this.projectService.getProjectItemsForRace(this.race()._id).pipe(
      map((items) => {
        let history: Project[] = [];
        let started: Project[] = [];
        let notStarted: Project[] = [];

        (items as Project[]).forEach((item) => {
          if (item.state === "completed") {
            history.push(item);
          }
          else if (item.state === "started") {
            started.push(item);
            // itemHash[item.code] = item;
          }
          else {
            notStarted.push(item);
            // itemHash[item.code] = item;
          }
        });
        return {
          history: history,
          started: started,
          notStarted: notStarted
        };
      })
    ).subscribe((results) => {
      this.history.set(results.history);
      this.started.set(results.started);
      this.notStarted.set(results.notStarted);
    });
  };

  start (project: Project) {
    let transaction = this.transactionByCode()[project.code];
    console.log("lookup transaction: ", transaction);
    if (!transaction) {
      transaction = Object.assign(
        this.newTransaction(),
        {
          amount: project.startUpFee,
          locator: project.code,
          description: 'Development cost for the ' + project.symbol
        }
      );
    }
    transaction.amount = project.startUpFee;
    console.log("adjust transaction: ", transaction);

    this.saveTransaction(transaction);
  }

  cancel (project: Project) {
    let transaction = this.transactionByCode()[project.code];
    console.log("delete transaction: ", transaction);
    this.openDeleteTransactionDialog("Development Transaction", transaction);
  };
};
