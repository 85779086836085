<div>Colonization</div>

<div class="my-2 flex items-start">
  <economic-summary-table [turn]="session.turnEdit()" [incomeTotal]="incomeTotal" [expensesTotal]="expensesTotal()" [extractedHeading]="'Colonization:'" [extractedTotal]="total()"></economic-summary-table>

  <table id="_LOGISTICS_" class="ml-2 ism-white">
    <tr class="data">
      <td class="text-center text-1.5 ism-gold" colspan="2">Logistics</td>
    </tr>
    <tr class="data">
      <td class="text-right">Base CFN Q / H:</td>
      <td class="text-right">{{ baseCfnQ }} / {{ baseCfnH }}</td>
    </tr>
    <tr class="data">
      <td class="text-right">Used Q / H:</td>
      <td class="text-right">{{ cfnQUsed }} / {{ cfnHUsed }}</td>
    </tr>
    <tr class="data">
      <td class="text-right">Free PTU:</td>
      <td class="text-right">{{ freePtuByStarSystemId["total"] || 0 }}</td>
    </tr>
    <tr class="data">
      <td class="text-right">Extracted PTU:</td>
      <td class="text-right">{{ ptuExtracted()["total"] || 0 }}</td>
    </tr>
    <!-- <tr class="data">
      <td class="text-right">Available PTU:</td>
      <td class="text-right">{{ (freePtuByStarSystemId["total"] || 0) + (ptuExtracted()["total"] || 0) }}</td>
    </tr> -->
    <tr class="data">
      <td class="text-right">Placed PTU:</td>
      <td class="text-right">{{ ptuPlaced()["total"] || 0 }} / {{ (freePtuByStarSystemId["total"] || 0) + (ptuExtracted()["total"] || 0) }}</td>
    </tr>
    <tr class="data">
      <td class="text-right">Remaining PTU:</td>
      <td class="text-right">{{ (freePtuByStarSystemId["total"] || 0) + (ptuExtracted()["total"] || 0) - (ptuPlaced()["total"] || 0) }} / {{ (freePtuByStarSystemId["total"] || 0) + (ptuExtracted()["total"] || 0) }}</td>
    </tr>
    <tr class="data">
      <td class="text-right">Current Expense:</td>
      <td class="text-right">{{ total() | number: "1.2-2" }} MC</td>
    </tr>
  </table>
</div>

<div id="_colonization-center" class="my-2 flex flex-col border-0 ism-white">
  <div class="mt-4 py-2 text-1.5 font-bold">Star Systems</div>
  <table class="w-full">
    <tr>
      <th class="w-1/5 text-center">System Number</th>
      <th class="w-1/5 text-center">Free PTU</th>
      <th class="w-1/5 text-center whitespace-normal">Extracted PTU</th>
      <th class="w-1/5 text-center whitespace-normal">Planned PTU</th>
      <th class="w-1/5 text-center">Available PTU</th>
      <th class="text-center">Open</th>
    </tr>
  </table>

  <cdk-accordion>
    @for (informationStarSystem of starSystemsRestricted(); track informationStarSystem._id) {
      <cdk-accordion-item #accordionItem="cdkAccordionItem" role="button" tabindex="0" [attr.id]="'accordion-header-' + $index" [attr.aria-controls]="'accordion-body-' + $index" [attr.aria-description]="'Multiple System Display'">
        <!-- Summary -->
        <div class="flex items-center justify-between text-1.25 font-bold text-center" (click)="accordionItem.toggle()">
          <table class="w-full border-white">
            <tr class="border-white">
              <td class="w-1/5 m-0 p-0 border-0">{{ informationStarSystem.starSystemNumber }}</td>
              <td class="w-1/5 m-0 p-0 border-0">
                {{ freePtuByStarSystemId[informationStarSystem.starSystemId] || 0 | number: "1.0-0" }}
              </td>
              <td class="w-1/5 m-0 p-0 border-0">
                {{ ptuExtracted()[informationStarSystem.starSystemNumber] || 0 }}
              </td>
              <td class="w-1/5 m-0 p-0 border-0">
                {{ ptuPlaced()[informationStarSystem.starSystemNumber] || 0 | number: "1.0-0" }}
              </td>
              <td class="w-1/5 m-0 p-0 border-0">
                {{ (freePtuByStarSystemId["total"] || 0) + (ptuExtracted()["total"] || 0) - (ptuPlaced()["total"] || 0) | number: "1.0-0" }}
              </td>
              <td class="border-0">
                <chevron-toggle [isOpen]="accordionItem.expanded"></chevron-toggle>
              </td>
            </tr>
          </table>
        </div>
        <!-- Details -->
        <div class="w-full flex flex-col" role="region" [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-sub-body-' + $index" [attr.aria-labelledby]="'accordion-header-' + $index">
          <cdk-accordion>
            <cdk-accordion-item #freePtuAccordionItem="cdkAccordionItem" role="button" tabindex="0" [attr.id]="'freeptu-header-' + $index" [attr.aria-controls]="'accordion-body-' + $index" [attr.aria-description]="'Multiple System Display'">
              <!-- Summary -->
              <div class="flex items-center justify-between" (click)="freePtuAccordionItem.toggle()">
                <table class="w-full text-1.25 ism-gold">
                  <tr>
                    <td class="w-1/5 p-2 m-0 border-0">Free PTU:</td>
                    <td class="w-1/5 p-2 m-0 text-center border-0">
                      {{ freePtuByStarSystemId["total"] || 0 | number: "1.0-0" }}
                    </td>
                    <td class="w-3/5 p-2 m-0 text-center border-0"></td>
                    <td class="border-0">
                      <chevron-toggle [isOpen]="freePtuAccordionItem.expanded" [classString]="'w-5 h-5 ism-gold'"></chevron-toggle>
                    </td>
                  </tr>
                </table>
              </div>

              <!-- Details -->
              <div class="flex justify-end border" [style.display]="freePtuAccordionItem.expanded ? '' : 'none'">
                <table class="m-2">
                  <tr>
                    <th>Locator</th>
                    <th>Free PTU</th>
                  </tr>
                  @for (popInfo of freePopulationInfo[informationStarSystem.starSystemId] | orderBy: "locator"; track $index) {
                    <tr class="data">
                      <td class="whitespace-nowrap">{{ popInfo.locator }}</td>
                      <td class="whitespace-nowrap text-center">{{ popInfo.freePtu }}</td>
                    </tr>
                  }
                </table>
              </div>
            </cdk-accordion-item>

            <cdk-accordion-item #extractedPtuAccordionItem="cdkAccordionItem" role="button" tabindex="0" [attr.id]="'freeptu-header-' + $index" [attr.aria-controls]="'accordion-body-' + $index" [attr.aria-description]="'Multiple System Display'">
              <!-- Summary -->
              <div (click)="extractedPtuAccordionItem.toggle()">
                <table class="w-full text-1.25 no-underline ism-gold">
                  <tr>
                    <td class="w-1/5 p-2 m-0 border-0">Extracted PTU:</td>
                    <td class="w-1/5 p-2 m-0 text-center border-0"></td>
                    <td class="w-1/5 p-2 m-0 text-center border-0">
                      {{ ptuExtracted()[informationStarSystem.starSystemNumber] || 0 }}
                    </td>
                    <td class="w-2/5 p-2 m-0 text-center border-0"></td>
                    <td class="border-0">
                      <chevron-toggle [isOpen]="extractedPtuAccordionItem.expanded" [classString]="'w-5 h-5 ism-gold'"></chevron-toggle>
                    </td>
                  </tr>
                </table>
              </div>

              <!-- Details -->
              <div class="flex justify-end border" [style.display]="extractedPtuAccordionItem.expanded ? '' : 'none'">
                <table class="m-4">
                  <tr>
                    <th>Locator</th>
                    <th class="text-center">PTU</th>
                    @if (canEdit) {
                      <th class="text-center">Actions</th>
                    }
                  </tr>
                  @for (extraction of extractionsByStarSystem()[informationStarSystem.starSystemNumber] | orderBy: "locator"; track $index) {
                    <tr class="data">
                      <td class="whitespace-nowrap text-center">{{ extraction.locator }}</td>
                      <td class="whitespace-nowrap text-center">{{ 0 - extraction.quantity }}</td>
                      @if (canEdit) {
                        <td>
                          <div class="flex items-center justify-between">
                            <a type="button" data-text="Edit" class="mx-2 flex items-center tooltip-tr" (click)="editExtraction(informationStarSystem, extraction)">
                              <ism-svg [name]="'pencilSquare'"></ism-svg>
                            </a>
                            <button type="button" data-text="Delete" class="mx-2 flex items-center bg-transparent tooltip-tr" [ngClass]="{ 'border border-red-400': (freePtuByStarSystemId[informationStarSystem.starSystemId] || 0) + (ptuExtracted()[informationStarSystem.starSystemNumber] || 0) - (ptuPlaced()['total'] || 0) + extraction.quantity < 0, 'border-transparent': (freePtuByStarSystemId[informationStarSystem.starSystemId] || 0) + (ptuExtracted()[informationStarSystem.starSystemNumber] || 0) - (ptuPlaced()['total'] || 0) + extraction.quantity >= 0 }" (click)="deleteColonization(extraction)" [disabled]="(freePtuByStarSystemId[informationStarSystem.starSystemId] || 0) + (ptuExtracted()[informationStarSystem.starSystemNumber] || 0) - (ptuPlaced()['total'] || 0) + extraction.quantity < 0">
                              <ism-svg [name]="'xSquare'"></ism-svg>
                            </button>
                          </div>
                        </td>
                      }
                    </tr>
                  }
                  @if (canEdit) {
                    <tr class="data">
                      <td colspan="3" class="text-right align-top">
                        <div class="flex items-center justify-end">
                          <a type="button" data-text="Add" class="mx-4 flex items-center tooltip-tr" (click)="createExtraction(informationStarSystem)">
                            Add New Extraction
                            <ism-svg [name]="'plus'" class="pl-1"></ism-svg>
                          </a>
                        </div>
                      </td>
                    </tr>
                  }
                </table>
              </div>
            </cdk-accordion-item>

            <cdk-accordion-item #plannedPtuAccordionItem="cdkAccordionItem" role="button" tabindex="0" [attr.id]="'freeptu-header-' + $index" [attr.aria-controls]="'accordion-body-' + $index" [attr.aria-description]="'Multiple System Display'">
              <!-- Summary -->
              <div (click)="plannedPtuAccordionItem.toggle()">
                <table class="w-full no-underline text-1.25 ism-gold">
                  <tr>
                    <td class="w-2/5 p-2 m-0 border-0" colspan="2">Population Transport Orders:</td>
                    <td class="w-1/5 p-2 m-0 text-center border-0"></td>
                    <td class="w-1/5 p-2 m-0 text-center border-0">
                      {{ ptuPlaced()[informationStarSystem.starSystemNumber] || 0 | number: "1.0-0" }}
                    </td>
                    <td class="w-1/5 p-2 m-0 text-center border-0"></td>
                    <td class="border-0">
                      <chevron-toggle [isOpen]="plannedPtuAccordionItem.expanded" [classString]="'w-5 h-5 ism-gold'"></chevron-toggle>
                    </td>
                  </tr>
                </table>
              </div>
              <!-- Details -->
              <div class="flex justify-end border" [style.display]="plannedPtuAccordionItem.expanded ? '' : 'none'">
                <table class="w-full">
                  <tr>
                    <th class="text-center">Type</th>
                    <th>Locator</th>
                    <th class="text-center">Q</th>
                    <th class="text-center">H</th>
                    <th class="text-center">PTU</th>
                    <th class="text-center">Cost</th>
                    @if (canEdit) {
                      <th class="text-center">Actions</th>
                    }
                  </tr>
                  @for (colonization of internalFreeByStarSystem()[informationStarSystem.starSystemNumber] | orderBy: "locator"; track $index) {
                    <tr class="data">
                      <td class="text-center">
                        <div class="tooltip-tr" data-text="Local Free Shipping">LF</div>
                      </td>
                      <td>{{ colonization.locator }}</td>
                      <td colspan="2" class="text-center">In System CFN - Free</td>
                      <td class="text-center">{{ colonization.quantity }}</td>
                      <td class="text-right">{{ colonization.amount || 0 | number: "1.0-0" }} MC</td>
                      @if (canEdit) {
                        <td>
                          <div class="flex items-center justify-around">
                            <a type="button" data-text="Edit" class="mx-2 flex items-center tooltip-tr" (click)="editTransaction(informationStarSystem, colonization)">
                              <ism-svg [name]="'pencilSquare'"></ism-svg>
                            </a>
                            <a type="button" data-text="Delete" class="mx-2 flex items-center tooltip-tr" (click)="deleteColonization(colonization)">
                              <ism-svg [name]="'xSquare'"></ism-svg>
                            </a>
                          </div>
                        </td>
                      }
                    </tr>
                  }
                  @for (colonization of internalByStarSystem()[informationStarSystem.starSystemNumber] | orderBy: "locator"; track $index) {
                    <tr class="data">
                      <td class="text-center">
                        <div class="tooltip-tr" data-text="In-System CFN Shipping">L</div>
                      </td>
                      <td>{{ colonization.locator }}</td>
                      <td class="text-center">{{ colonization.qInUse }}</td>
                      <td class="text-center">{{ colonization.hInUse }}</td>
                      <td class="text-center">{{ colonization.quantity }}</td>
                      <td class="text-right">{{ colonization.amount || 0 | number: "1.0-0" }} MC</td>
                      @if (canEdit) {
                        <td>
                          <div class="flex items-center justify-around">
                            <a type="button" data-text="Edit" class="mx-2 flex items-center tooltip-tr" (click)="editTransaction(informationStarSystem, colonization)">
                              <ism-svg [name]="'pencilSquare'"></ism-svg>
                            </a>
                            <a type="button" data-text="Delete" class="mx-2 flex items-center tooltip-tr" (click)="deleteColonization(colonization)">
                              <ism-svg [name]="'xSquare'"></ism-svg>
                            </a>
                          </div>
                        </td>
                      }
                    </tr>
                  }
                  @for (colonization of interstellarByStarSystem()[informationStarSystem.starSystemNumber] | orderBy: "locator"; track $index) {
                    <tr class="data">
                      <td class="text-center">
                        <div class="tooltip-tr" data-text="CFN Shipping">CFN</div>
                      </td>
                      <td>{{ colonization.locator }}</td>
                      <td class="text-center">{{ colonization.qInUse }}</td>
                      <td class="text-center">{{ colonization.hInUse }}</td>
                      <td class="text-center">{{ colonization.quantity }}</td>
                      <td class="text-right">{{ colonization.amount || 0 | number: "1.0-0" }} MC</td>
                      @if (canEdit) {
                        <td>
                          <div class="flex items-center justify-around">
                            <div class="flex items-center justify-around">
                              <a type="button" data-text="Edit" class="mx-2 flex items-center tooltip-tr" (click)="editTransaction(informationStarSystem, colonization)">
                                <ism-svg [name]="'pencilSquare'"></ism-svg>
                              </a>
                              <a type="button" data-text="Delete" class="mx-2 flex items-center tooltip-tr" (click)="deleteColonization(colonization)">
                                <ism-svg [name]="'xSquare'"></ism-svg>
                              </a>
                            </div>
                          </div>
                        </td>
                      }
                    </tr>
                  }
                  @if ((excessFreightFee?.amount || 0) > 0) {
                    <tr class="data">
                      <td colspan="3">{{ excessFreightFee.description }}</td>
                      <td class="text-center">{{ excessFreightFee.qInUse }}</td>
                      <td class="text-center">{{ excessFreightFee.hInUse }}</td>
                      <td class="text-right">{{ excessFreightFee.amount || 0 | number: "1.0-0" }} MC</td>
                      <td></td>
                    </tr>
                  }
                  @if (canEdit && (freePtuByStarSystemId["total"] || 0) + (ptuExtracted()["total"] || 0) - (ptuPlaced()["total"] || 0) > 0) {
                    <tr class="data">
                      <td colspan="7" class="text-right align-top">
                        <div class="flex items-center justify-end">
                          <button type="button" data-text="Add" class="mx-4 flex items-center tooltip" (click)="createColonization(informationStarSystem)" [disabled]="(freePtuByStarSystemId['total'] || 0) + (ptuExtracted()['total'] || 0) - (ptuPlaced()['total'] || 0) <= 0">
                            Add Population Transfer
                            <ism-svg [name]="'plus'" class="pl-1"></ism-svg>
                          </button>
                        </div>
                      </td>
                    </tr>
                  }
                </table>
              </div>
            </cdk-accordion-item>
          </cdk-accordion>
        </div>
      </cdk-accordion-item>
    }
  </cdk-accordion>
</div>

<!-- Overflow Fleets -->
<!-- #region OVERFLOW FLEETS -->
<div class="mt-8 w-full flex items-center">
  <div class="text-1.5 ism-white font-bold">Overflow Fleets</div>
</div>

<table class="my-4 text-1.25 w-1/2 ism-white">
  <tr>
    <th>Fleet Name</th>
    <th>Q</th>
    <th>Qs</th>
    <th>H</th>
    <th>Hs</th>
  </tr>
  @for (fleet of fleets(); track fleet._id) {
    <tr class="data">
      <td class="text-right">{{ fleet.fleetName }}</td>
      <td class="text-right">{{ fleet["totalCounts"]["Q"] || 0 }}</td>
      <td class="text-right">{{ fleet["totalCounts"]["Qs"] || 0 }}</td>
      <td class="text-right">{{ fleet["totalCounts"]["H"] || 0 }}</td>
      <td class="text-right">{{ fleet["totalCounts"]["Hs"] || 0 }}</td>
    </tr>
  }
</table>
