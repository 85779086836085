<div id="_Construction-Jobs_" class="iBTTiny:mx-auto md:m-0 iBTTiny:w-11/12 md:w-full flex flex-col">
  <div class="ism-gold font-bold text-1.5">{{ pageTitle }}</div>

  <div>
    <economic-summary-table [turn]="session.turnEdit()" [incomeTotal]="incomeTotal" [expensesTotal]="expensesTotal()" [extractedHeading]="'Construction:'" [extractedTotal]="total()"></economic-summary-table>
  </div>
  <div id="_shipyard-modules_" class="my-2 flex flex-col border-0 ism-white">
    @for (starSystem of hostStarSystems(); track starSystem._id) {
      <div class="text-1.25">StarSystem Number: {{ starSystem.number }}</div>
      @for (shipyard of shipyardsByStarSystemId[starSystem._id]; track shipyard._id) {
        <div>
          <div class="text-1.25">Ship Name: {{ shipyard.shipName }} ({{ shipyard.syCount }} SYs)</div>
          <div>
            @for (shipyardModule of [].constructor(shipyard.syCount); track $index) {
              <div>
                <div class="my-1 text-1.25 ism-gold">Shipyard Module #{{ $index + 1 }}:</div>
                <table class="w-full table-auto">
                  <tr>
                    <th>Class</th>
                    <th>Name</th>
                    <th><span class="tooltip-tr" data-text="Destination Fleet">Fleet</span></th>
                    @if (canEdit) {
                      <th class="tooltip-tr" data-text="Reserved Hull Spaces">Reserved</th>
                    }
                    <th>Costs</th>
                    <th class="tooltip-tr" data-text="Turn Started">Start</th>
                    <th class="tooltip-tr" data-text="Hull Spaces Remaining">To Go</th>
                    @if (canEdit) {
                      <th class="text-center">Actions</th>
                    }
                  </tr>
                  @for (constructionKey of allShipYardKeys[shipyard._id + "-" + $index]; track constructionKey) {
                    @if (hashEconomicData[constructionKey]; as constructionTransaction) {
                      <tr>
                        <td>{{ shipClassHash[constructionTransaction.shipClassId].className }}</td>
                        <td>{{ constructionTransaction.description }}</td>
                        <td>{{ fleetsById[constructionTransaction.fleetId2]?.fleetName || constructionTransaction.fleetId2 }}</td>
                        @if (canEdit) {
                          <td class="text-center">{{ constructionTransaction.size }}</td>
                        }
                        <td class="text-right">{{ constructionTransaction.amount }} MC</td>
                        <td class="text-center">{{ constructionTransaction.turn }}</td>
                        <td class="text-center">{{ constructionTransaction.size }}</td>
                        @if (canEdit) {
                          <td>
                            @if (constructionTransaction.turn == session.turnEdit()) {
                              <div class="flex items-center justify-around">
                                <a class="mx-2 flex items-center tooltip-tr" type="button" data-text="Edit" (click)="newOrEdit(constructionKey)">
                                  <ism-svg [name]="'pencilSquare'"></ism-svg>
                                </a>
                                @if (isMaster) {
                                  <a type="button" class="mx-2 flex items-center tooltip-tr" data-text="Delete" (click)="delete(constructionKey)">
                                    <ism-svg [name]="'xSquare'"></ism-svg>
                                  </a>
                                }
                              </div>
                            }
                          </td>
                        }
                      </tr>
                    }
                  }
                  @if (canEdit && nextShipYardKeys[shipyard._id + "-" + $index] != "notyet") {
                    <tr class="data">
                      <td colspan="9" class="text-right">
                        @if (nextShipYardKeys[shipyard._id + "-" + $index]) {
                          <div class="flex items-center justify-end">
                            Add Another Ship
                            <a id="_ADD-svgPlus_" type="button" data-text="Add" class="mx-4 flex items-center tooltip-tr" (click)="newOrEdit(nextShipYardKeys[shipyard._id + '-' + $index])">
                              <ism-svg [name]="'plus'"></ism-svg>
                            </a>
                          </div>
                        } @else if (!nextShipYardKeys[shipyard._id + "-" + $index]) {
                          <div class="flex items-center justify-end">
                            Add New Ship
                            <a type="button" data-text="Add" class="mx-4 flex items-center" (click)="newOrEdit(session.turnEdit() + '-' + shipyard._id + '-' + $index + '-1')">
                              <ism-svg [name]="'plus'"></ism-svg>
                            </a>
                          </div>
                        }
                      </td>
                    </tr>
                  }
                </table>
              </div>
            }
          </div>
        </div>
      }
    }
  </div>
</div>
