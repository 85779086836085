import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CdkListboxModule } from '@angular/cdk/listbox';
import { EconomicsModule } from '../economics/economics.module';
import { ExpensesRoutingModule } from './expenses-routing.module';
import { SharedModule } from '../shared/shared.module';

import { ColonizationComponent } from './colonization/colonization.component';
import { ConstructionComponent } from './construction/construction.component';
import { ConstructionEditComponent } from './construction-edit/construction-edit.component';
import { EconomicLevelResearchComponent } from './economic-level-research/economic-level-research.component';
import { EmplacementEditComponent } from './emplacement-edit/emplacement-edit.component';
import { ExtractionEditComponent } from './extraction-edit/extraction-edit.component';
import { IndustrialExpansionComponent } from './industrial-expansion/industrial-expansion.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { OtherExpensesComponent } from './other-expenses/other-expenses.component';
import { TechItemDevelopmentComponent } from './tech-item-development/tech-item-development.component';
import { TroopsComponent } from './troops/troops.component';
import { TroopRecruitingComponent } from './troop-recruiting/troop-recruiting.component';
import { IndustrialUnitEditComponent } from './industrial-unit-edit/industrial-unit-edit.component';

@NgModule({
  declarations: [
    ColonizationComponent,
    ConstructionComponent,
    ConstructionEditComponent,
    EconomicLevelResearchComponent,
    EmplacementEditComponent,
    ExtractionEditComponent,
    IndustrialExpansionComponent,
    MaintenanceComponent,
    OtherExpensesComponent,
    TechItemDevelopmentComponent,
    TroopsComponent,
    TroopRecruitingComponent,
    IndustrialUnitEditComponent,
  ],
  imports: [
    CdkAccordionModule,
    CdkListboxModule,
    CommonModule,
    EconomicsModule,
    ExpensesRoutingModule,
    FormsModule,
    SharedModule,
  ]
})
export class ExpensesModule { }
