<edit-dialog (action)="signalAction($event)" [title]="title" class="flex flex-col ism-black">
  <ng-container edit-body>
    <div class="modal-center flex flex-col">
      <div class="w-full px-2 text-black flex flex-col bg-yellow-100 border-gray-700 border-solid border-1">
        <div id="_LINE-1_" class="px-2 flex items-center justify-center">
          <!-- <div>Locator: {{populationInformation['locator']}}</div> -->
          <!-- <div>Mode: {{mode}}</div> -->
          <div class="w-20 border text-center" [ngClass]="{ 'bg-red-400 border-red-800': mode === 'Sell' }" (click)="setMode('Sell')">Sell</div>
          <div class="w-20 border text-center" [ngClass]="{ 'bg-green-400 border-green-800': mode === 'Buy' }" (click)="setMode('Buy')">Buy</div>
        </div>
        <div id="_LINE-2-EMPLACE_" class="px-2 flex items-center justify-between">
          <div class="tooltip-tr" data-text="Population Transport Unit">Industrial Units - Limits:</div>
          <div class="text-center">Max Sell<br />{{ populationInformation["iu"] }}</div>
          <div class="text-center">Max Buy<br />{{ populationInformation["maxIu"] - populationInformation["iu"] }}</div>
        </div>
      </div>

      <gray-label-wrapper [fieldLabel]="'Industrial Unit'" [fieldName]="'iu'" class="w-full text-black flex items-center">
        <input class="w-20 px-2 form-input" type="number" (change)="calcCost()" min="{{ min }}" max="{{ max }}" [(ngModel)]="industrialUnitTransaction.quantity" required />
      </gray-label-wrapper>

      @let label = mode === "Sell" ? "Proceeds" : "Cost";
      <gray-label-wrapper [fieldLabel]="label" [fieldName]="'credits'" class="w-full text-black flex items-center">
        <span class="px-4">{{ industrialUnitTransaction.amount }} MC</span>
      </gray-label-wrapper>
    </div>
  </ng-container>
</edit-dialog>
