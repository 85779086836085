import { Component } from '@angular/core';
import { SimpleListComponent } from '../../shared/simple-list/simple-list.component';

@Component({
  selector: 'maintenance',
  templateUrl: '../../shared/simple-list/simple-list.component.html',})
export class MaintenanceComponent extends SimpleListComponent {
  override pageTitle: string = 'Ship Maintenance';
  override listKey: string = "maintenance";
  override displayType: string = 'Ship';
}
