<div #planetarycontrolforces>
  <div class="font-bold ism-gold text-2">
    {{ pageTitle }}
  </div>

  <div class="my-2 flex items-start">
    <economic-summary-table [turn]="session.turnEdit()" [incomeTotal]="incomeTotal" [expensesTotal]="expensesTotal()" [extractedHeading]="'Recruitment:'" [extractedTotal]="total()"></economic-summary-table>
  </div>

  <div>
    <input id="show_all" type="checkbox" [(ngModel)]="showAll" />
    <label for="show_all">Show All</label>
  </div>

  <div class="my-2 flex flex-col border-0 ism-white">
    <cdk-accordion>
      @for (informationStarSystem of starSystemsWithPopulations() | orderBy: "starSystemNumber"; track informationStarSystem) {
        <cdk-accordion-item #accordionItem="cdkAccordionItem" role="button" tabindex="0" [attr.id]="'accordion-header-' + $index" [attr.aria-controls]="'accordion-body-' + $index" [attr.aria-description]="'Multiple System Display'">
          <!-- Summary -->
          <div #accordionSummary class="flex items-center justify-between text-1.25 font-bold text-center" (click)="accordionItem.toggle()" [scrollToTop]="accordionSummary" [onTriggerOnly]="true" [triggered]="accordionItem.expanded">
            <table class="w-full border-white">
              <tr class="border-white">
                <td class="w-1/5 m-0 p-0 border-0">Star System: {{ informationStarSystem.starSystemNumber }}</td>
                <td class="border-0">
                  <chevron-toggle [isOpen]="accordionItem.expanded"></chevron-toggle>
                </td>
              </tr>
            </table>
          </div>
          <!-- Details -->
          <div class="w-full flex flex-col" role="region" [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-sub-body-' + $index" [attr.aria-labelledby]="'accordion-header-' + $index">
            <table>
              <tr>
                <th class="sticky top-0 bg-black border-b border-white border-solid">Locator</th>
                @if (!readOnly) {
                  <th class="text-right sticky top-0 bg-black border-b border-white border-solid">Max PCF</th>
                  <th class="text-right sticky top-0 bg-black border-b border-white border-solid">PCF</th>
                  <th class="text-right sticky top-0 bg-black border-b border-white border-solid">PCF-a</th>
                  <th class="text-right sticky top-0 bg-black border-b border-white border-solid">Total</th>
                }
                <th class="text-right sticky top-0 bg-black border-b border-white border-solid">&Delta; PCF</th>
                <th class="text-right sticky top-0 bg-black border-b border-white border-solid">&Delta; PCF-a</th>
                <th class="text-right sticky top-0 bg-black border-b border-white border-solid">&Delta; Total</th>
                @if (!readOnly) {
                  <th class="text-center sticky top-0 bg-black border-b border-white border-solid">Action</th>
                }
              </tr>
              @for (informationPopulation of starSystemsPopulationInfo[informationStarSystem.starSystemId] | orderBy: "locator"; track informationPopulation) {
                @if (showAll || (transactionLookupHash[informationPopulation.planetId]?.PCFQuantity || 0) + (transactionLookupHash[informationPopulation.planetId]?.PCFaQuantity || 0) > 0) {
                  <tr>
                    <td>{{ informationPopulation.locator }}</td>
                    @if (!readOnly) {
                      <td class="text-right">{{ informationPopulation.pu }}</td>
                      <td class="text-right">{{ pcfCountsByBodyId()[informationPopulation.planetId]?.pcf }}</td>
                      <td class="text-right">{{ pcfCountsByBodyId()[informationPopulation.planetId]?.pcfa }}</td>
                      <td class="text-right">{{ pcfCountsByBodyId()[informationPopulation.planetId]?.total }}</td>
                    }
                    <td class="text-right">
                      {{ transactionLookupHash[informationPopulation.planetId]?.PCFQuantity || 0 | number: "1.0-0" }}
                    </td>
                    <td class="text-right">
                      {{ transactionLookupHash[informationPopulation.planetId]?.PCFaQuantity || 0 | number: "1.0-0" }}
                    </td>
                    <td class="text-right">{{ (transactionLookupHash[informationPopulation.planetId]?.PCFAmount || 0) + (transactionLookupHash[informationPopulation.planetId]?.PCFaAmount || 0) | number: "1.1-1" }} MC</td>
                    @if (!readOnly) {
                      <td>
                        <div class="flex justify-center">
                          @if ((transactionLookupHash[informationPopulation.planetId]?.PCFQuantity || 0) != 0 || (transactionLookupHash[informationPopulation.planetId]?.PCFaQuantity || 0) != 0) {
                            <a id="_svgPencil-EDIT" type="button" class="mx-2 tooltip-tr" data-text="Edit" (click)="openTroopTransactionDialog(informationPopulation.planetId)">
                              <ism-svg [name]="'pencilSquare'" [classString]="'w-6 h-6  flex items-center text-yellow-300 hover:text-white visited:text-yellow-300'"></ism-svg>
                            </a>
                            <a id="_svgTrash-DELETE" type="button" class="mx-2 tooltip-tr" data-text="Delete" (click)="deleteTroopTransactionDialog(informationPopulation.planetId)">
                              <ism-svg [name]="'trashcan'" [classString]="'w-6 h-6 flex items-center text-yellow-300 hover:text-white visited:text-yellow-300'"></ism-svg>
                            </a>
                          }
                          @if ((transactionLookupHash[informationPopulation.planetId]?.PCFQuantity || 0) === 0 && (transactionLookupHash[informationPopulation.planetId]?.PCFaQuantity || 0) === 0) {
                            <a id="_svgPlus-Add" type="button" class="mx-2 tooltip-tr" data-text="Add" (click)="openTroopTransactionDialog(informationPopulation.planetId)">
                              <ism-svg [name]="'plus'" [classString]="'w-6 h-6 flex items-center text-yellow-300 hover:text-white visited:text-yellow-300'"></ism-svg>
                            </a>
                            <a id="_svgMinus-REMOVE" type="button" class="mx-2 tooltip-tr" data-text="Remove" (click)="openTroopTransactionDialog(informationPopulation.planetId)">
                              <ism-svg [name]="'minus'" [classString]="'w-6 h-6 flex items-center text-yellow-300 hover:text-white visited:text-yellow-300'"></ism-svg>
                            </a>
                          }
                        </div>
                      </td>
                    }
                  </tr>
                }
              }
              <tr>
                <td [attr.colspan]="readOnly ? 2 : 7" class="text-right">Total:</td>
                <td class="text-right">{{ total() | number: "1.1-1" }} MC</td>
                @if (!readOnly) {
                  <td></td>
                }
              </tr>
            </table>
          </div>
        </cdk-accordion-item>
      }
    </cdk-accordion>
  </div>
</div>
