<div>{{ pageTitle }}</div>

@if (authorizedMarshal) {
  <div><input type="checkbox" [(ngModel)]="skyMarshalMode" /> Sky Marshal Mode</div>
}

<div class="flex">
  <economic-summary-table [turn]="session.turnEdit()" [incomeTotal]="incomeTotal" [expensesTotal]="expensesTotal()" [extractedHeading]="'Item Development:'" [extractedTotal]="total()"></economic-summary-table>
</div>

<cdk-accordion class="ism-accordion flex flex-col">
  <cdk-accordion-item id="accordion-not-started" class="ism-accordion-item" #accordionNotStarted="cdkAccordionItem" role="button" [attr.aria-controls]="'accordion-body-not-started'" [attr.aria-description]="'not started tech item development'" [disabled]="notStarted().length === 0">
    <div #accordionHeaderNotStarted id="accordion-header-not-started" class="w-full flex items-center" [scrollToTop]="accordionHeaderNotStarted" (click)="accordionNotStarted.toggle()">
      <chevron-toggle [isOpen]="accordionNotStarted.expanded"></chevron-toggle>
      Not Started <span>( {{ notStarted().length }} Projects )</span>
    </div>

    <table id="accordion-body-not-started" class="text-1.25 w-fit" role="region" [style.display]="accordionNotStarted.expanded ? '' : 'none'" [attr.aria-labelledby]="'accordion-header-not-started'">
      <tr class="data">
        <th>Tech Level</th>
        @if (skyMarshalMode) {
          <th>Code</th>
        }
        <th>Symbol</th>
        <th>Description</th>
        @if (skyMarshalMode) {
          <th>Required Points</th>
        }
        <th>Amount</th>
        <th>Action</th>
      </tr>
      @for (project of notStarted(); track $index) {
        <tr class="data">
          <td class="text-right">{{ project.techLevel }}</td>
          @if (skyMarshalMode) {
            <td class="text-right">{{ project.code }}</td>
          }
          <td class="text-center">{{ project.symbol }}</td>
          <td>{{ project.description }}</td>
          @if (skyMarshalMode) {
            <td class="text-right">{{ project.requiredPoints }}</td>
          }
          <td class="text-right">{{ project.startUpFee }} MC</td>
          <td>
            @if (!transactionByCode()[project.code] || transactionByCode()[project.code].amount === 0) {
              <button class="mx-2 px-2 btn" (click)="start(project)">Start</button>
            }
            @if (transactionByCode()[project.code] && transactionByCode()[project.code].amount > 0) {
              <button class="mx-2 px-2 btn" (click)="cancel(project)">Cancel</button>
            }
          </td>
        </tr>
      }
    </table>
  </cdk-accordion-item>

  <cdk-accordion-item id="accordion-started" class="ism-accordion-item" #accordionStarted="cdkAccordionItem" role="button" [attr.aria-controls]="'accordion-body-started'" [attr.aria-description]="'started tech item development'" [disabled]="started().length === 0">
    <div #accordionHeaderStarted id="accordion-header-started" class="w-full flex items-center" [scrollToTop]="accordionHeaderStarted" (click)="accordionStarted.toggle()">
      <chevron-toggle [isOpen]="accordionStarted.expanded"></chevron-toggle>
      Started <span>( {{ started().length }} Projects )</span>
    </div>

    <table id="accordion-body-started" class="text-1.25 w-fit" role="region" [style.display]="accordionStarted.expanded ? '' : 'none'" [attr.aria-labelledby]="'accordion-header-started'">
      <tr class="data">
        <th>Turn Started</th>
        <th>Tech Level</th>
        @if (skyMarshalMode) {
          <th>Code</th>
        }
        <th>Symbol</th>
        <th>Description</th>
        @if (skyMarshalMode) {
          <th>Earned Points</th>
          <th>Required Points</th>
        }
        <th>Amount</th>
      </tr>
      @for (project of started(); track $index) {
        <tr class="data">
          <td>{{ project.turnStarted }}</td>
          <td class="text-right">{{ project.techLevel }}</td>
          @if (skyMarshalMode) {
            <td class="text-right">{{ project.code }}</td>
          }
          <td class="text-center">{{ project.symbol }}</td>
          <td>{{ project.description }}</td>
          @if (skyMarshalMode) {
            <td class="text-right">{{ project.earnedPoints }}</td>
            <td class="text-right">{{ project.requiredPoints }}</td>
          }
          <td class="text-right">{{ project.startUpFee }} MC</td>
        </tr>
      }
    </table>
  </cdk-accordion-item>

  <cdk-accordion-item id="accordion-history" class="ism-accordion-item" #accordionHistory="cdkAccordionItem" role="button" [attr.aria-controls]="'accordion-body-history'" [attr.aria-description]="'tech item development history'" [disabled]="history().length === 0">
    <div #accordionHeaderHistory id="accordion-header-history" class="w-full flex items-center" [scrollToTop]="accordionHeaderHistory" (click)="accordionHistory.toggle()">
      <chevron-toggle [isOpen]="accordionHistory.expanded"></chevron-toggle>
      History <span>( {{ history().length }} Projects )</span>
    </div>

    <table id="accordion-body-history" class="text-1.25 w-fit" role="region" [style.display]="accordionHistory.expanded ? '' : 'none'" [attr.aria-labelledby]="'accordion-header-history'">
      <tr class="data">
        <th>Tech Level</th>
        @if (skyMarshalMode) {
          <th>Code</th>
        }
        <th>Symbol</th>
        <th>Description</th>
        @if (skyMarshalMode) {
          <th>Turn Started</th>
          <th>Earned Points</th>
          <th>Required Points</th>
        }
        <th>Amount</th>
      </tr>
      @for (project of history(); track $index) {
        <tr class="data">
          <td class="text-right">{{ project.techLevel }}</td>
          @if (skyMarshalMode) {
            <td class="text-right">{{ project.code }}</td>
          }
          <td class="text-center">{{ project.symbol }}</td>
          <td>{{ project.description }}</td>
          @if (skyMarshalMode) {
            <td>{{ project.turnStarted }}</td>
            <td class="text-right">{{ project.earnedPoints }}</td>
            <td class="text-right">{{ project.requiredPoints }}</td>
          }
          <td class="text-right">{{ project.startUpFee }} MC</td>
        </tr>
      }
    </table>
  </cdk-accordion-item>
</cdk-accordion>
