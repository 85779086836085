import { Component, WritableSignal, effect, inject, signal } from '@angular/core';

import { DialogHandlerComponent } from '../../shared/dialog-handler/dialog-handler.component';

import { TransactionService } from '../../services/transaction.service';

import { InformationPopulation } from '../../interfaces/information-population';
import { Transaction } from '../../interfaces/transaction';

@Component({
  selector: 'app-extraction-edit',
  templateUrl: './extraction-edit.component.html',
})
export class ExtractionEditComponent extends DialogHandlerComponent {
  private transactionService = inject(TransactionService);
  transaction: Transaction;
  title: string;
  starSystemNumber: string;
  populationInformationMap: { [key: string]: InformationPopulation; } = {};
  populationInformation: InformationPopulation[] = [];

  selectedInformationPopulation: WritableSignal<InformationPopulation | undefined> = signal(undefined);
  selectedPtu: WritableSignal<number> = signal(0);

  disabled: string | boolean;
  oldPtuOverage: number = 0;
  newPtu: number = 0;
  newPu: number = 0;
  newPtuOverage: number = 0;

  constructor () {
    super();
    this.transaction = this.data.document as Transaction;
    this.title = this.data['documentName'];
    this.starSystemNumber = this.data['starSystemNumber'];
    this.populationInformationMap = this.data['populationInformationMap'];
    this.populationInformation = Object.values(this.populationInformationMap).filter(
      popInfo => (popInfo.pu > 0)
    );

    this.selectedPtu.set(0 - this.transaction.quantity);
    if (this.transaction.bodyId) {
      let sip = this.populationInformationMap[this.transaction.bodyId];
      this.selectedInformationPopulation.set(sip);
    }

    effect(() => {
      let sip = this.selectedInformationPopulation();
      if (!sip) {
        return;
      }

      let basePtu = this.transactionService.puToPtu(sip.pu);
      console.log("basePtu: %o, sip.ptu: %o", basePtu, sip.ptu);
      this.oldPtuOverage = sip.ptu - basePtu;

      this.transaction.locator = sip.locator;
      this.transaction.informationPopulationId = sip._id;
      this.transaction.bodyId = sip.planetId;
      this.transaction.quantity = Math.min(-this.selectedPtu(), 0);

      let newPtu = 0;
      if (this.selectedInformationPopulation()) {
        newPtu = this.selectedInformationPopulation().ptu - this.selectedPtu();
      }
      this.newPtu = newPtu;
      this.newPu = this.transactionService.ptuToPu(newPtu);
      this.newPtuOverage = newPtu - this.transactionService.puToPtu(this.newPu);
    });
  };

  override save () {
    this.dialogRef.close({
      documentName: "Transaction",
      document: this.transaction
    });
  };
}
