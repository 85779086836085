import { Injectable, inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { Observable, Subject, map, of, shareReplay, switchMap, tap } from "rxjs";

import { Race } from "../interfaces/race";
import { toSignal } from '@angular/core/rxjs-interop';

interface PcfCount {
  bodyId: string;
  pcfType: string;
  count: number
};
export interface PcfCounts {
  [bodyId: string]: {
    pcf: number;
    pcfa: number;
    total: number;
  }
}

@Injectable({
  providedIn: 'root'
})
export class PcfService {
  private http = inject(HttpClient);

  constructor() { }

  getPcfCountsByBodyIdForRaceId(raceId: string): Observable<PcfCounts> {
    return this.http.get<PcfCount[]>('/api/pcf/raceId/' + raceId + '/counts').pipe(
      map(results => {
        let countsByBodyId = results.reduce((finalresult, countRecord) => {
          let interimCount = finalresult[countRecord['bodyId']] || {pcf: 0, pcfa: 0};
          let pcfCount = interimCount['pcf'];
          let pcfaCount = interimCount['pcfa'];
          if (countRecord.pcfType === 'PCF') { pcfCount += (countRecord['quantity'] || 0)}
          if (countRecord.pcfType === 'PCF-a') { pcfaCount += (countRecord['quantity'] || 0)}
          finalresult[countRecord['bodyId']] = {
            pcf: pcfCount,
            pcfa: pcfaCount,
            total: pcfCount + pcfaCount,
          }
          return finalresult;
        }, {} as PcfCounts);
        return countsByBodyId;
      })
    )
  };
}
