<edit-dialog (action)="signalAction($event)" [title]="title" class="flex flex-col ism-black">
  <ng-container edit-body>
    <div class="modal-center flex flex-col">
      <div class="w-full px-2 text-black flex flex-col bg-yellow-100 border-gray-700 border-solid border-1">
        <div id="_LINE-1_" class="px-2 flex items-center justify-between">
          <div>Star System: {{ starSystemNumber }}</div>
          <div>Mode: {{ modeText }}</div>
        </div>
        <div id="_LINE-2-EMPLACE_" class="px-2 flex items-center justify-end">
          <div class="tooltip-tr" data-text="Population Transport Unit">PTU, place up to:</div>
          <div class="text-gray-400">
            @if (selectedInformationPlanet) {
              {{ maxPtu }}
            } @else {
              [Please select a body!]
            }
          </div>
        </div>
      </div>

      <gray-label-wrapper [fieldLabel]="'Destination Planet / Moon'" [fieldName]="'body'" class="w-full text-black flex items-center">
        <select class="px-2 form-select" [(ngModel)]="selectedInformationPlanet" required (change)="setSelectedInformationPlanet()" [disabled]="disabled">
          @for (surveyed of surveyedBodies | orderBy: "locator"; track surveyed) {
            <option [ngValue]="surveyed">
              {{ surveyed.locator + " (" + ((populationInformationMap[surveyed.bodyId] || {}).pu || 0) + "/" + ((populationInformationMap[surveyed.bodyId] || {}).maxPu || 0) + ", hd:" + surveyed.habitDifference + ", roi:" + surveyed.rei + ")" }}
            </option>
          }
        </select>
      </gray-label-wrapper>

      <gray-label-wrapper [fieldLabel]="'Population Transport Unit'" [fieldName]="'ptu'" class="w-full text-black flex items-center">
        <input class="px-2 form-input" type="number" min="0" max="{{ maxPtu }}" [(ngModel)]="colonizationTransaction.quantity" (change)="updatePtu()" required [disabled]="!selectedInformationPlanet" />
      </gray-label-wrapper>

      @if (selectedInformationPlanet) {
        <hr class="w-full border-1 border-yellow-400" />
        <gray-label-wrapper [fieldLabel]="'Resources per PTU'" [fieldName]="'resources_per_ptu'" class="w-full text-black flex items-center">
          <div>Q: 10, H: {{ hMultiplier * 10 }}</div>
        </gray-label-wrapper>
        <gray-label-wrapper [fieldLabel]="'CFN Resources'" [fieldName]="'cfn_resources'" class="w-full text-black flex items-center">
          <div>Q: {{ colonizationTransaction.qInUse }}, H: {{ colonizationTransaction.hInUse }}</div>
        </gray-label-wrapper>
        <gray-label-wrapper [fieldLabel]="'Cost'" [fieldName]="'cost'" class="w-full text-black flex items-center">
          <div>{{ colonizationTransaction.amount || 0 | number: "1.0-0" }} MC</div>
        </gray-label-wrapper>
      }
    </div>
  </ng-container>
</edit-dialog>
