<edit-dialog (action)="signalAction($event)" [title]="title" class="flex flex-col">
  <ng-container edit-body>
    <div id="_SUBTITLE_" class="font-bold text-black flex justify-center">
      <div class="flex items-center">
        <div class="p-2">{{ this.shipName }}</div>
        <div class="p-2">Shipyard Module #{{ this.shipYardNumber }}</div>
      </div>
    </div>
    <div class="modal-center flex flex-col ism-black">
      <gray-label-wrapper [fieldLabel]="'Class'" [fieldName]="'class'" class="w-full text-black flex items-center">
        <select id="shipClass" class="p-1 form-select flex-2" [(ngModel)]="shipClass" required (change)="setShipClass()" [disabled]="disabled">
          @for (shipClass of shipClasses | orderBy: "className"; track shipClass) {
            <option [ngValue]="shipClass">{{ shipClass.className + " (" + shipClass.hullClass + ")" }}</option>
          }
        </select>
      </gray-label-wrapper>
      <gray-label-wrapper [fieldLabel]="'Name'" [fieldName]="'name'" class="w-full text-black flex items-center">
        <input id="ship_name" type="text" class="p-1 form-input flex-2" [(ngModel)]="constructionTransaction.description" required />
      </gray-label-wrapper>
      <gray-label-wrapper [fieldLabel]="'Fleet'" [fieldName]="'fleet'" class="w-full text-black flex items-center">
        <select id="fleet" class="p-1 form-select flex-2" [(ngModel)]="constructionTransaction.fleetId2" required [disabled]="disabled">
          @for (fleet of fleets() | orderBy: "fleetName"; track fleet) {
            <option [ngValue]="fleet._id">{{ fleet.fleetName }}</option>
          }
        </select>
      </gray-label-wrapper>
      <gray-label-wrapper [fieldLabel]="'Busy Hull Spaces'" [fieldName]="'busy_hull_spaces'" class="w-full text-black flex items-center">
        <span class="p-1 pl-2 flex-2">{{ shipClass?.actualSize }}</span>
      </gray-label-wrapper>
      <gray-label-wrapper [fieldLabel]="'Construction Time'" [fieldName]="'construction_time'" class="w-full text-black flex items-center">
        <span class="p-1 pl-2 flex-2">{{ (shipClass?.actualSize || 0) / hullsPerMonth | number: "1.0-1" }} Turns</span>
      </gray-label-wrapper>
      <gray-label-wrapper [fieldLabel]="'Cost'" [fieldName]="'cost'" class="w-full text-black flex items-center">
        <span class="p-1 pl-2 flex-2">{{ shipClass?.buildCost || 0 }}</span>
      </gray-label-wrapper>
    </div>
  </ng-container>
</edit-dialog>
